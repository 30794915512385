import axios from 'axios';


export const dczVerificarLegal = async (dCZ) => {
  try {
    const response = await axios({
          method: 'POST',
          url: process.env.REACT_APP_BASE_URL2+"dcz_verificar_legal",
          data: dCZ,
          headers: {
            'Content-Type': 'application/json'
          }
    })

    return response;
  } catch (error) {
    throw error;
  }
};

export const call_api_function = async (endpoint, payload) => {
 //[get_personalization,/perfil/checar_cupones,company-user-images,save_personalization,'logout','upload-image','upload-user-image',get-user-image','perfil/lista_asignados','perfil/lista_asignados','reenviar_nip','get_dcz','ver_reporte']
  try {
    const response = await axios({
        method: 'post', 
        url: endpoint,
        data:payload
    })
    return response;
  } catch (error) {
    throw error;
  }
};
