export const dataTypesConfig = {
  family: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Relationship",
      "landingpage.leftContent.tabs.table_headers.Name",
      "landingpage.leftContent.tabs.table_headers.Age",
      "landingpage.leftContent.tabs.table_headers.PEP",
      // "landingpage.leftContent.tabs.table_headers.Status",
    ],
    createRow: createData,
    createParams: ["Parentesco", "Nombre", "Edad", "PEP"],
  },
  beneficiaries: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Relationship",
      "landingpage.leftContent.tabs.table_headers.Name",
      "landingpage.leftContent.tabs.table_headers.Age",
      "landingpage.leftContent.tabs.table_headers.PEP",
    ],
    createRow: createBeneficiaryData,
    createParams: ["Parentesco", "Nombre", "Edad", "PEP"],
  },
  listed_weeks: {
    // columnHeaders: ['landingpage.leftContent.tabs.table_headers.Type','landingpage.leftContent.tabs.table_headers.Actor'],
    createRow: createListedWeekData,
    createParams: ["Tipo", "Valor"],
  },
  total_employees_and_duration: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Employer",
      "landingpage.leftContent.tabs.table_headers.Duration",
      "landingpage.leftContent.tabs.table_headers.High_Low",
      "landingpage.leftContent.tabs.table_headers.Salary",
    ],
    createRow: createEmployee,
    createParams: ["Empleador", "Duracion", "Alta_Baja", "Salario"],
  },
  salary: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Employer",
      "landingpage.leftContent.tabs.table_headers.Year",
      "landingpage.leftContent.tabs.table_headers.Salary",
      "landingpage.leftContent.tabs.table_headers.Percentage",
    ],
    createRow: createSalary,
    createParams: ["Empleador", "Anio", "Salario", "Porcentaje"],
  },
  despidos: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Employer",
      "Estado",
      "Inactividad",
    ],
    createRow: createDespidos,
    createParams: ["Empleador", "Estado", "Inactividad"],
  },
  imss: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Institution",
      "landingpage.leftContent.tabs.table_headers.Weeks",
      "landingpage.leftContent.tabs.table_headers.Days",
    ],
    createRow: createImss,
    createParams: ["Institución", "Semanas", "Dias"],
  },
  labor_lawsuits_settlement: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Instance",
      "landingpage.leftContent.tabs.table_headers.Type",
      "landingpage.leftContent.tabs.table_headers.Actor",
      "landingpage.leftContent.tabs.table_headers.Defendant",
      "landingpage.leftContent.tabs.table_headers.Expert No",
      "landingpage.leftContent.tabs.table_headers.Probabilty",
    ],
    createRow: createLabor,
    createParams: [
      "Instancia",
      "Tipo_Juicio",
      "Actor",
      "Demandado",
      "Expediente",
      "HomonymScore",
    ],
  },
  civil_suits_settlement: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Instance",
      "landingpage.leftContent.tabs.table_headers.Type",
      "landingpage.leftContent.tabs.table_headers.Actor",
      "landingpage.leftContent.tabs.table_headers.Defendant",
      "landingpage.leftContent.tabs.table_headers.Expert No",
      "landingpage.leftContent.tabs.table_headers.Probabilty",
    ],
    createRow: createCivil,
    createParams: [
      "Instancia",
      "Tipo_Juicio",
      "Actor",
      "Demandado",
      "Expediente",
      "HomonymScore",
    ],
  },
  lawsuits_family_settlement: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Instance",
      "landingpage.leftContent.tabs.table_headers.Type",
      "landingpage.leftContent.tabs.table_headers.Actor",
      "landingpage.leftContent.tabs.table_headers.Defendant",
      "landingpage.leftContent.tabs.table_headers.Expert No",
      "landingpage.leftContent.tabs.table_headers.Probabilty",
    ],
    createRow: createFamilySuit,
    createParams: [
      "Instancia",
      "Tipo_Juicio",
      "Actor",
      "Demandado",
      "Expediente",
      "HomonymScore",
    ],
  },
  criminal_lawsuits_settlement: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Instance",
      "landingpage.leftContent.tabs.table_headers.Type",
      "landingpage.leftContent.tabs.table_headers.Actor",
      "landingpage.leftContent.tabs.table_headers.Defendant",
      "landingpage.leftContent.tabs.table_headers.Expert No",
      "landingpage.leftContent.tabs.table_headers.Probabilty",
    ],
    createRow: createCriminalSuit,
    createParams: [
      "Instancia",
      "Tipo_Juicio",
      "Actor",
      "Demandado",
      "Expediente",
      "HomonymScore",
    ],
  },
  administrative_demands_settlement: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Instance",
      "landingpage.leftContent.tabs.table_headers.Type",
      "landingpage.leftContent.tabs.table_headers.Actor",
      "landingpage.leftContent.tabs.table_headers.Defendant",
      "landingpage.leftContent.tabs.table_headers.Expert No",
      "landingpage.leftContent.tabs.table_headers.Probabilty",
    ],
    createRow: createAdministrativeDemads,
    createParams: [
      "Instancia",
      "Tipo_Juicio",
      "Actor",
      "Demandado",
      "Expediente",
      "HomonymScore",
    ],
  },
  Partidos: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Political Party",
      "landingpage.leftContent.tabs.table_headers.Charge",
      "landingpage.leftContent.tabs.table_headers.Position",
      "landingpage.leftContent.tabs.table_headers.States",
      "landingpage.leftContent.tabs.table_headers.Election date",
    ],
    createRow: createPartidos,
    createParams: ["partido", "cargo", "puesto", "entidad", "elección"],
  },
  Funcionarios: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Institution",
      "landingpage.leftContent.tabs.table_headers.Position",
      "landingpage.leftContent.tabs.table_headers.Salary",
    ],
    createRow: createFuncionarios,
    createParams: ["institución", "puesto", "salario"],
  },
  Sindicatos: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Laboral Union",
      "landingpage.leftContent.tabs.table_headers.Charge",
      "landingpage.leftContent.tabs.table_headers.Position",
      "landingpage.leftContent.tabs.table_headers.Validity",
    ],
    createRow: createSindicatos,
    createParams: ["sindicato", "cargo", "posición", "vigencia"],
  },
  deuda: {
    // Remove header as per new design
    // columnHeaders: ['landingpage.leftContent.tabs.table_headers.Type','landingpage.leftContent.tabs.table_headers.Actor'],
    createRow: createDeuda,
    createParams: ["Tipo", "Valor"],
  },
  mensuales: {
    // Remove header as per new design
    // columnHeaders: ['landingpage.leftContent.tabs.table_headers.Type','landingpage.leftContent.tabs.table_headers.Actor'],
    createRow: createDeuda,
    createParams: ["Tipo", "Valor"],
  },
  maximo: {
    // Remove header as per new design
    // columnHeaders: ['landingpage.leftContent.tabs.table_headers.Type','landingpage.leftContent.tabs.table_headers.Actor'],
    createRow: createDeuda,
    createParams: ["Tipo", "Valor"],
  },
  historical_pago: {
    // Remove header as per new design
    // columnHeaders: ['landππ∏ngpage.leftContent.tabs.table_headers.Type','landingpage.leftContent.tabs.table_headers.Actor'],
    createRow: createDeuda,
    createParams: ["Tipo", "Valor"],
  },
  // section F
  clinica_arreglo: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Organization",
      "landingpage.leftContent.tabs.table_headers.Assigned_Unit",
    ],
    createRow: createClinica,
    createParams: ["Organización", "Unidad"],
  },
  geoloc: {
    columnHeaders: [
      "landingpage.leftContent.tabs.table_headers.Label",
      "landingpage.leftContent.tabs.table_headers.Hours",
      "landingpage.leftContent.tabs.table_headers.Direction",
      "landingpage.leftContent.tabs.table_headers.Possible Location",
      "landingpage.leftContent.tabs.table_headers.Type",
    ],
    createRow: createGeoLoc,
    createParams: ["Label", "Fecha", "Direccion", "Localizacion", "Tipo"],
  },
};
// Functions
export function createData(relationship, name, age, PEP) {
  return { relationship, name, age, PEP };
}

export function createBeneficiaryData(relationship, name, age, PEP) {
  return { relationship, name, age, PEP };
}

export function createListedWeekData(tipo, valor) {
  return { tipo, valor };
}

export function createEmployee(empleador, duracion, alta_baja, salario) {
  return { empleador, duracion, alta_baja, salario };
}

export function createSalary(empleador, anio, salario, porcentaje) {
  return { empleador, anio, salario, porcentaje };
}

export function createDespidos(empleador, estado, inactividad) {
  return { empleador, estado, inactividad };
}

export function createImss(institución, semanas, dias) {
  return { institución, semanas, dias };
}

export function createPartidos(partido, cargo, puesto, entidad, elección) {
  partido = partido || "---";
  cargo = cargo || "---";
  puesto = puesto || "---";
  entidad = entidad || "---";
  elección = elección || "---";
  return { partido, cargo, puesto, entidad, elección };
}

export function createFuncionarios(institución, puesto, salario) {
  institución = institución || "---";
  puesto = puesto || "---";
  salario = salario || "---";
  return { institución, puesto, salario };
}

export function createSindicatos(sindicato, cargo, posición, vigencia) {
  sindicato = sindicato || "---";
  cargo = cargo || "---";
  posición = posición || "---";
  vigencia = vigencia || "---";
  return { sindicato, cargo, posición, vigencia };
}

export function createLabor(instancia, tipo, actor, demandado, expediente, homonymScore) {
  instancia = instancia || "---";
  tipo = tipo || "---";
  actor = actor || "---";
  demandado = demandado || "---";
  expediente = expediente || "---";
  homonymScore = homonymScore || "---";
  return { instancia, tipo, actor, demandado, expediente, homonymScore };
}

export function createCivil(instancia, tipo, actor, demandado, expediente, homonymScore) {
  instancia = instancia || "---";
  tipo = tipo || "---";
  actor = actor || "---";
  demandado = demandado || "---";
  expediente = expediente || "---";
  homonymScore = homonymScore || "---";
  return { instancia, tipo, actor, demandado, expediente, homonymScore };
}
export function createFamilySuit( instancia, tipo, actor, demandado, expediente, homonymScore ) {
  instancia = instancia || "---";
  tipo = tipo || "---";
  actor = actor || "---";
  demandado = demandado || "---";
  expediente = expediente || "---";
  homonymScore = homonymScore || "---";
  return { instancia, tipo, actor, demandado, expediente, homonymScore };
}
export function createCriminalSuit( instancia, tipo, actor, demandado, expediente, homonymScore ) {
  instancia = instancia || "---";
  tipo = tipo || "---";
  actor = actor || "---";
  demandado = demandado || "---";
  expediente = expediente || "---";
  homonymScore = homonymScore || "---";
  return { instancia, tipo, actor, demandado, expediente, homonymScore };
}
export function createAdministrativeDemads( instancia, tipo, actor, demandado, expediente, homonymScore ) {
  instancia = instancia || "---";
  tipo = tipo || "---";
  actor = actor || "---";
  demandado = demandado || "---";
  expediente = expediente || "---";
  homonymScore = homonymScore || "---";
  return { instancia, tipo, actor, demandado, expediente, homonymScore };
}
//D
export function createDeuda(tipo, valor) {
  return { tipo, valor };
}
// F
export function createClinica(organización, unidad) {
  return { organización, unidad };
}

export function createGeoLoc(label, fecha, direccion, localizacion, tipo) {
  return { label, fecha, direccion, localizacion, tipo };
}
