import React, {
  useEffect,
  useLayoutEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AuthorizationCheckbox from "./AuthorizationCheckbox";
import { Box, Card, Typography, Divider, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

import axios from "axios";
import sjcl from "sjcl";
import { useNavigate } from "react-router-dom";
import { dczVerificarLegal } from "../../utils/verifyAPICalls.js";
import {
  timeSleep,
  getPersonalization,
  checkCurpZero,
  imprimir,
  verReport,
  getReport,
  checkCupons,
  getEspera,
  userCupons,
} from "../../utils/bureauHelper";
import { 
  getEspera as getEEspera, 
  getReport as getEReport, 
  checkCupons as checkECupons, 
  verReport as verEReport
} from "../../utils/locales/EbureauHelper";
import {
  AesEncrypt,
  setKeysDict,
  getKeysSaltAndIv,
} from "../../utils/encryptionHelper";
import {
  isAxiosResponse,
  isAxiosError,
  serverRequest,
  serverRequest2,
} from "../../utils/requestHelper";
import { getCookiesDict } from "../../utils/cookiesHelper";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader.js";
import { call_api_function } from "../../utils/verifyAPICalls";
import AlertModal from "../@common/Modal/AlertModal";
import TrashModalpanel from "../@common/Modal/TrashModalpanel";
import authimage from "../../assets/images/authorize-user.svg";
import LightBlackComponent from "../@common/Modal/LightBlackComponent";
import RevisionLegalComponent from "../@common/Modal/RevisionLegalComponent";
import FaceIDGeoLocComponent from "../@common/Modal/FaceIDGeoLocComponent";
import MessageIcon from "@mui/icons-material/Message";
import EditIcon from "@mui/icons-material/Edit";
import trashimage from "../../assets/images/Iconly-Bold-Delete.svg";
import Authorizeimage from "../../assets/images/Authorization Icon.svg";
import reportimggreen from "../../assets/images/reporticongreen.svg";
import reportimgyellow from "../../assets/images/reporticonyellow.svg";
import reporticonred from "../../assets/images/reporticonred.svg";
import reporticonblue from "../../assets/images/reporticonblue.svg";
import refreshiconblue from "../../assets/images/refresh-icon.svg";
import facegeoiconblue from "../../assets/images/faceid-n-geoloc-blue.svg";
import referenceIcon from "../../assets/images/referenceIconUpdate.svg";
import legalIcon from "../../assets/images/Legal Reviews Icon.svg";
import islIcon from "../../assets/images/iSL Module.svg";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../@common/CustomSpinner/customSpinner";
import Tooltip from "@mui/material/Tooltip";
import { processCompanyData } from "../../utils/getColorHelper.js";
import ReportEmailSendModal from "../@common/Modal/ReportEmailSendModal.js";
import ReportUpdateModal from "../@common/Modal/ReportUpdateModal.js";
import { deepClone } from "@mui/x-data-grid/utils/utils.js";
import emodulePanel from "../../../src/assets/images/company-enterprise-icon_orange.svg";
import consultPanel from "../../../src/assets/images/IF_Orange.svg";
import greenCircle from "../../../src/assets/images/greenCircle.png";
import redCircle from "../../../src/assets/images/redCircle.png";
import yellowCircle from "../../../src/assets/images/yellowCircle.png";
import greyCircle from "../../../src/assets/images/greyCircle.png";
import indicateIcon from "../../../src/assets/images/indicateIcon.png";
import SendEmailConmponent from "../@common/Modal/sendEmailConmponent.js";
import EmailSuccessfullySent from "../@common/Modal/EmailSuccessfullySent.js";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AuthorizationPanelTable = forwardRef((props) => {
  const {
    currentPage,
    itemsPerPage,
    panelList,
    groupPanelList,
    groupPanelList1,
    panelList1,
    setPanelList,
    setpanelList1,
    setGroupPanelList,
    setGroupPanelList1,
    subUserEmail,
    setSubUserEmail
  } = props;
  const { t } = useTranslation();
  const [cookieList, setCookieList] = useState([]);
  const [showElement, setShowElement] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [showRevision, setShowRevision] = useState(false);
  const [showFaceGeo, setShowFaceGeo] = useState(false);
  const [curp, setCurp] = useState(false);
  const [nombre, setNombre] = useState(false);
  const [usuarioCupon, setUsuarioCupon] = useState("SB");
  const [currentItem, setCurrentItem] = useState(null);
  const [itemId, setItemId] = useState(false);
  const [itemIdCB, setItemIdCB] = useState(false);
  const [itemIdNBC, setItemIdNBC] = useState(false);
  const [itemGeoloc, setItemGeoloc] = useState("");
  const [itemFaceId, setItemFaceId] = useState("");
  const [showAllResendIcon, setShowAllResendIcon] = useState(false);
  const [showAllRevisionIcon, setShowAllRevisionIcon] = useState(false);
  const [email, setEmail] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [showAlert, setShowAlert] = useState(false); // Add a state to control the visibility of the alert
  const [showtext, setShowtext] = useState("");
  const [cookies, setCookies] = useState(getCookiesDict());
  const [returnTimeSleep, setReturnTimeSleep] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [favoriteItems, setFavoriteItems] = useState([]); // New state for favorite items
  const [moduleType, setModuleType] = useState("");

  const [showEmail, setShowEmail] = useState(false);
  const [emailSentModalOpen, setEmailSentModalOpen] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [vistosReporte, setVistosReporte] = useState(null);
  const [vistosRevisionSummary, setVistosRevisionSummary] = useState(null);
  const [vistosRevisionHomonym, setVistosRevisionHomonym] = useState(null);
  const [vistosRevisionChat, setVistosRevisionChat] = useState(null);
  const [tempDate, SetTempDate] = useState();
  const [checkUpdate, setCheckUpdate] = useState(false);
  const [noOfCoupons, setNoOfCoupons] = useState({
    CB: { disponible: 0, total: 0 },
    SB: { disponible: 0, total: 0 },
  });
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [isUpdatedModal, setIsUpdatedModal] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;

    // Update selectedCheckboxes based on isChecked
    const updatedCheckboxes = isChecked ? props.data.map((row) => row._id) : [];

    setSelectedCheckboxes(updatedCheckboxes);
    setSelectAllChecked(isChecked);
  };

  const handleRowCheckboxChange = (event, rowId) => {
    const isChecked = event.target.checked;

    // Update selectedCheckboxes based on isChecked for the individual row
    setSelectedCheckboxes((prevSelected) =>
      isChecked
        ? [...prevSelected, rowId]
        : prevSelected.filter((id) => id !== rowId)
    );
    setSelectAllChecked(false);
  };

  const handleSelectAllClick = () => {
    const allCheckboxIds = props.data.map((row) => row._id);
    setSelectedCheckboxes(allCheckboxIds);
    setSelectAllChecked(true);
  };

  const handleUnselectAllClick = () => {
    setSelectedCheckboxes([]);
    setSelectAllChecked(false);
  };

  const UserCoupons = async () => {
    try {
      const formdata = new FormData();
      formdata.append("email", cookies.email);
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "perfil/checar_cupones",
        formdata
      );

      if (response.status === 200) {
        setNoOfCoupons(response.data);
      } else {
        setNoOfCoupons({
          CB: { disponible: 0, total: 0 },
          SB: { disponible: 0, total: 0 },
        });
      }
    } catch (error) {
      setShowAlert(true);
      setShowtext("Error:", error);
    }
  };
  useEffect(() => {
    UserCoupons(cookies.email);
  }, []);
  useEffect(() => {
    const element1 = document.getElementById("SB");
    const element2 = document.getElementById("CB");
    if (element1) {
      element1.innerText = `${noOfCoupons.SB?.disponible}/${noOfCoupons.SB?.total}`;
    }

    if (element2) {
      element2.innerText = `${noOfCoupons.CB?.disponible}/${noOfCoupons.CB?.total}`;
    }
  }, [noOfCoupons]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const openModal = (flag, errorMsg) => {
    setShowAlert(flag);
    setShowtext(errorMsg);
  };

  const handleDeleteCandidate = (item) => {
    setItemId(item._id);
    setModuleType(item.module_type);
    setIsModalOpen(true);
  };
  const handleclosedDeleteCandidate = () => {
    setItemId("");
    setModuleType("");
    setIsModalOpen(false);
  };
  const handleReportCandidate = (
    event,
    item
  ) => {
    handleIconClick(event);
    setShowResend(true);
    setShowAllResendIcon(true);
    setItemId(item._id);
    setItemIdCB(item._id_CB);
    setEmail(item.email);
    setNombre(item.name);
    setCurp(item.curp);
    setUsuarioCupon(item.usuario_cupon);
    setVistosReporte(item.vistos_reporte);
    setModuleType(item.module_type);
  };
  const handleResendCandidate = (item, date, event) => {
    setShowResend(true);
    setItemId(item._id);
    setModuleType(item.module_type);
    setSelectedDate(date);
  };
  const handleRevisionLegal = (
    event,
    item
  ) => {
    handleIconClick(event);
    setShowRevision(true);
    setShowAllRevisionIcon(true);
    setCurp(item.curp);
    setItemId(item._id);
    setItemIdNBC(item._id_NBC);
    setVistosReporte(item.vistos_reporte);
    setVistosRevisionSummary(item.vistos_summary);
    setVistosRevisionHomonym(item.vistos_homonym);
    setVistosRevisionChat(item.vistos_chat);
    setModuleType(item.module_type);
  };
  const handleFaceGeo = (
    event,
    item
  ) => {
    handleIconClick(event);
    setShowFaceGeo(true);
    setCurrentItem(item);
  }
  const [clickLocation, setClickLocation] = useState(null);
  const handleIconClick = (event) => {
    // Get the click coordinates relative to the viewport
    const x = event.clientX;
    const y = event.clientY;
    setClickLocation({ x, y });
  };
  const handleResendCandidateClosed = () => {
    setShowResend(false);
    setShowAllResendIcon(false);
    setEmail("");
    setCurp("");
    setItemId("");
    setItemIdCB("");
    setVistosReporte(null);
    setModuleType("");
  };
  const handleRevisionLegalClosed = () => {
    setShowRevision(false);
    setShowAllRevisionIcon(false);
    setCurp("");
    setItemId("");
    setItemIdNBC("");
    setVistosRevisionSummary(null);
    setVistosRevisionHomonym(null);
    setVistosRevisionChat(null);
    setModuleType("");
  };
  const handleFaceGeoClosed = () => {
    setShowFaceGeo(false);
    setCurrentItem(null);
  }

  const deleteCandidate = async (id) => {
    handleclosedDeleteCandidate();
    setIsLoading(true);
    var enc_data = {
      data_eeo: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, cookies.email, {
        iter: 500,
      }),
      data_ecd: sjcl.encrypt(process.env.REACT_APP_MIX_COOK_KEY, id, {
        iter: 500,
      }),
    };

    var data_eeo = JSON.parse(enc_data["data_eeo"]);
    var data_ecd = JSON.parse(enc_data["data_ecd"]);

    var dataForm = {
      data_eeo: {
        data_eeoi: data_eeo["iv"],
        data_eeos: data_eeo["salt"],
        data_eeoc: data_eeo["ct"],
      },
      data_ecd: {
        data_ecdi: data_ecd["iv"],
        data_ecds: data_ecd["salt"],
        data_ecdc: data_ecd["ct"],
      },
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
    };
    if (moduleType === "empresa") {
      dataForm["is_empresa"] = 1;
    }
    const response = await serverRequest(
      "post",
      "perfil/eliminar_consulta",
      dataForm
    );
    if (isAxiosResponse(response) && response.data) {
      if (Array.isArray(response.data)) {
        const updatedPanelList = panelList.filter(
          (candidate) => candidate._id !== id
        );
        const updatedPanelList1 = panelList1.filter(
          (candidate) => candidate._id !== id
        );
        setPanelList(updatedPanelList);
        setpanelList1(updatedPanelList1);
        const updatedGroupedData = Object.entries(groupPanelList).reduce(
          (acc, [date, items]) => {
            const updatedItems = items.filter(
              (candidate) => candidate._id !== id
            );
            if (updatedItems.length > 0) {
              acc[date] = updatedItems;
            }
            return acc;
          },
          {}
        );
        setGroupPanelList(updatedGroupedData);

        const updatedGroupedData1 = Object.entries(groupPanelList1).reduce(
          (acc, [date, items]) => {
            const updatedItems = items.filter(
              (candidate) => candidate._id !== id
            );
            if (updatedItems.length > 0) {
              acc[date] = updatedItems;
            }
            return acc;
          },
          {}
        );
        setGroupPanelList1(updatedGroupedData1);

        setIsLoading(false);
        return updatedPanelList;
      } else if (
        response.data == "error conexion" ||
        response.data == "error bd"
      ) {
        setIsLoading(false);
        openModal(true, "connection error");
      } else {
        setIsLoading(false);
        openModal(true, "Something went wrong");
      }
    }
    if (isAxiosError(response) && response.data) {
      setIsLoading(false);
      openModal(true, "Unable to delete record");
    }
  };

  const forwardEmail = async (id, email_type) => {
    handleResendCandidateClosed();
    setIsLoading(true);
    let formdata = new FormData();
    formdata.append("oid", id);
    if (email_type) {formdata.append("email_type", email_type)};
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "reenviar_nip",
        formdata
      );
      if (response.data === true) {
        setIsLoading(false);
        setEmailSentModalOpen(true);
      }
    } catch (e) {
      setIsLoading(false);
      openModal(true, e);
    }
  };

  const eForwardEmail = async (id) => {
    handleResendCandidateClosed();
    setIsLoading(true);
    let formdata = new FormData();
    formdata.append("oid", id);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "reenviar_e_nip",
        formdata
      );
      if (response.data === true) {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      openModal(true, e);
    }
  };

  const refreshReport = async (
    id,
    nbc_id,
    cnd_email,
    usuario_cupon,
    vistos_reporte
  ) => {
    try {
      const dataCurpZero = await updateReport(id);
      if (dataCurpZero === "error") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        return "error";
      }

      var dataLegalSB = new FormData();
      dataLegalSB.append("usrMail", cookies.email);
      dataLegalSB.append("mail", cnd_email);
      dataLegalSB.append("phono", dataCurpZero["sujeto_tel"]);
      dataLegalSB.append("caso", "caso2");
      dataLegalSB.append("nbc_id", dataCurpZero["id_NBC"]);
      dataLegalSB.append("geo_location", dataCurpZero["sujeto_geo_location"]);
      dataLegalSB.append("face_id", dataCurpZero["sujeto_face_id"]);

      const verifyResponse = await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL2 + "legal-Person-auth",
        data: dataLegalSB,
      });
      if (verifyResponse == "error bd") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "in,Conexión");
        return;
      }
      let new_vl_id = verifyResponse.data;

      dataCurpZero["sujeto_id"] = new_vl_id;
      let secciones_consulta = deepClone(dataCurpZero["secciones_consulta"]);
      let documents_status = deepClone(dataCurpZero["documents_status"]);

      dataCurpZero["secciones_consulta"] = JSON.stringify(secciones_consulta);
      dataCurpZero["documents_status"] = JSON.stringify(documents_status);

      const dczResponse = await dczVerificarLegal(dataCurpZero);
      if (!dczResponse.data) {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "Error Occured");
        return;
      }

      await serverRequest2("post", "autorizar_ppsb", { oid: new_vl_id });

      const returnCurpZero = await get_curp_zero(dataCurpZero, true);
      if (returnCurpZero === "error") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "Error Occured");
        return;
      }
      if (returnCurpZero.final_response?.status === "1") {
        const data = {
          _id: returnCurpZero.final_response.curps._id,
          nombre: returnCurpZero.final_response.cname,
          email: cookies.email,
          sucursal: returnCurpZero.petition.sujeto.sucursal,
          zona: returnCurpZero.petition.sujeto.zona,
          staff: returnCurpZero.petition.sujeto.staff,
          filtro_legal: returnCurpZero.petition.sujeto.filtro_legal,
          cliente: returnCurpZero.petition.sujeto.cliente,
          estado: returnCurpZero.petition.sujeto.buro.estado,
          colonia: returnCurpZero.petition.sujeto.buro.colonia,
          municipio: returnCurpZero.petition.sujeto.buro.municipio,
        };
        // await getEspera(returnCurpZero.final_response.curps._id);
        let resultados = await getReport(
          usuario_cupon,
          usuario_cupon,
          data,
          false,
          vistos_reporte,
          new_vl_id
        );
        await checkCupons(usuario_cupon, cookies.email);
        const personalization = await getPersonalization(usuario_cupon);
        await verReport(new_vl_id);

        setIsLoading(false);
        setSpinnerVisibility(false);

        navigate("/report", {
          state: {
            personalization: personalization,
            espData: processCompanyData(cookies.empresa, resultados),
            sub_sections_data: secciones_consulta,
            vlid: new_vl_id,
            curp: resultados["CURP"],
            usuarioCupon: usuario_cupon,
            sub_user_email: subUserEmail
          },
        });
      } else {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "Error Occured");
      }
    } catch (error) {
      console.error("Error refreshing report:", error);
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, error);
    }
  };

  const refreshEReport = async (id, cb_id, usuario_cupon) => {
    handleResendCandidateClosed();
    setSpinnerVisibility(true);
    let formdata = new FormData();
    formdata.append("sid", id);
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL2 + "e_update?" + `vl_id=${id}&cb_id=${cb_id}`)
      if (response.data.message === 'updated correctly') {
        let resultados = await getEReport(
          usuario_cupon,
          usuario_cupon,
          {},
          cb_id,
          false,
          undefined,
          id,
          cookies.email,
          response.data.data
        );
        checkECupons(usuario_cupon, cookies.email);
    
        await verEReport(id);
    
        setIsLoading(false);
        setSpinnerVisibility(false);
    
        navigate("/Ereport", {
            state: {
                espData: resultados,
                sub_sections_data: resultados.secciones,
                vlid: id,
                nombre: resultados["razon_social"],
                usuarioCupon: usuario_cupon,
                cbcId: cb_id
            },
        });
      } else {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "Error Occured");
        return "error";
      }
    } catch (e) {
      console.error("Error updating report:", e);
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, e);
      return "error";
    }
  };

  const showReportFirstTime = async (id, curp, email, is_update) => {
    const reportData = await updateReport(id);
    if (reportData === "error") {
      setIsLoading(false);
      setSpinnerVisibility(false);
      return "error";
    }
    const cz_response = await get_curp_zero(reportData, true);
    if (cz_response === "error") {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, "Error Occured");
      return;
    }
    if (cz_response.final_response?.status === "1") {
      const data = {
        _id: cz_response.final_response.curps._id,
        nombre: cz_response.final_response.cname,
        email: cookies.email,
        sucursal: cz_response.petition.sujeto.sucursal,
        zona: cz_response.petition.sujeto.zona,
        staff: cz_response.petition.sujeto.staff,
        filtro_legal: cz_response.petition.sujeto.filtro_legal,
        cliente: cz_response.petition.sujeto.cliente,
        estado: cz_response.petition.sujeto.buro.estado,
        colonia: cz_response.petition.sujeto.buro.colonia,
        municipio: cz_response.petition.sujeto.buro.municipio,
      };
      let resultados = await getReport(
        usuarioCupon,
        usuarioCupon,
        data,
        false,
        vistosReporte,
        id
      );
      checkCupons(usuarioCupon, cookies.email);
      const personalization = await getPersonalization(usuarioCupon);
      await verReport(id);
      setIsLoading(false);
      setSpinnerVisibility(false);
      navigate("/report", {
        state: {
          personalization: personalization,
          espData: processCompanyData(cookies.empresa, resultados),
          sub_sections_data: resultados.secciones,
          vlid: id,
          curp: resultados["CURP"],
          usuarioCupon: usuarioCupon,
          sub_user_email: subUserEmail
        },
      });
    } else {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, t("thanks.UNABLE"));
    }
  };

  const showEReportFirstTime = async (id, nombre, email, is_update) => {
    const reportData = await updateEReport(id);
    if (reportData === "error") {
      setIsLoading(false);
      setSpinnerVisibility(false);
      return "error";
    }
    let resultados = await getEReport(
      usuarioCupon,
      usuarioCupon,
      {},
      itemIdCB,
      false,
      undefined,
      id,
      cookies.email,
      reportData
    );
    checkECupons(usuarioCupon, cookies.email);

    await verEReport(id);

    setIsLoading(false);
    setSpinnerVisibility(false);

    navigate("/Ereport", {
        state: {
            espData: resultados,
            sub_sections_data: resultados.secciones,
            vlid: id,
            usuarioCupon: usuarioCupon,
            cbcId:itemIdCB

        },
    });
  };

  const showReport = async (id, curp, email, is_update) => {
    handleResendCandidateClosed();
    if (!is_update) {
      setIsLoading(true);
    }
    let queryParams = `vlid=${id}&curp=${curp}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&type=${usuarioCupon}&function=view&lang=${ localStorage.getItem("selectedLanguage") || "es" }&update=${is_update}`;
    try {
      if (vistosReporte === 0) {
        let resultados = await showReportFirstTime(id, curp, email, is_update);
        if (resultados === "error") {
          setIsLoading(false);
          setSpinnerVisibility(false);
          return "error";
        }
      } else {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL2 + "consult?" + queryParams
        );
        if (response.data == "not found") {
          setIsLoading(false);
          setSpinnerVisibility(false);
          openModal(true, t("thanks.NoUpdatedDocument"));
        } else if (response.data == "error bd") {
          setIsLoading(false);
          setSpinnerVisibility(false);
          openModal(true, "Error Occured");
        } else if (response.data.dicok) {
          const personalization = await getPersonalization(usuarioCupon);
          navigate("/report", {
            state: {
              personalization: personalization,
              espData: response.data.dicok,
              sub_sections_data: response.data.dicok.secciones,
              vlid: id,
              curp: curp,
              usuarioCupon: usuarioCupon,
              sub_user_email: subUserEmail
            },
          });
          verReport(id);
          checkCupons(usuarioCupon, cookies.email);
          setIsLoading(false);
          setSpinnerVisibility(false);
        }
      }
    } catch (error) {
      console.error("Error getting report:", error);
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, t("thanks.check"));
    }
  };
  // EReport start
  const showEReport = async (id, nombre, email, is_update) => {
    handleResendCandidateClosed();
    if (!is_update) {
      setIsLoading(true);
    }
    let queryParams = `vlid=${id}&nombre=${nombre}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&type=${usuarioCupon}&function=view&lang=${ localStorage.getItem("selectedLanguage") || "es" }&update=${is_update}`;
    try {
      if (vistosReporte === 0) {
        let resultados = await showEReportFirstTime(id, nombre, email, is_update);
        if (resultados === "error") {
          setIsLoading(false);
          setSpinnerVisibility(false);
          return "error";
        }
      } else {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL2 + "e_report?" + queryParams
        );
        if (response.data == "not found") {
          setIsLoading(false);
          setSpinnerVisibility(false);
          openModal(true, t("thanks.NoUpdatedDocument"));
        } else if (response.data == "error bd") {
          setIsLoading(false);
          setSpinnerVisibility(false);
          openModal(true, "Error Occured");
        } else if (response.data.dicok) {
          navigate("/Ereport", {
            state: {
              espData: response.data.dicok,
              sub_sections_data: response.data.dicok.secciones,
              vlid: id,
              nombre: response.data.dicok["razon_social"],
              usuarioCupon: usuarioCupon,
            },
          });
          verEReport(id);
          checkECupons(usuarioCupon, cookies.email);
          setIsLoading(false);
          setSpinnerVisibility(false);
        }
      }
    } catch (error) {
      console.error("Error getting report:", error);
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, t("thanks.check"));
    }
  };
  //EReport end

  const resendReport = async (id, curp, email, is_update) => {
    handleResendCandidateClosed();
    if (!is_update) {
      setIsLoading(true);
    }
    let queryParams = `vlid=${id}&curp=${curp}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&type=${usuarioCupon}&function=resend&lang=${ localStorage.getItem("selectedLanguage") || "es" }&update=${is_update}`;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + "consult?" + queryParams
      );
      if (response.data == "not found") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else if (response.data == "error bd") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "Error Occured");
      } else if (response.data == "ok") {
        verReport(id);
        checkCupons(usuarioCupon, cookies.email);
        setIsLoading(false);
        setSpinnerVisibility(false);
        setIsEmailModal(true);
      }
    } catch (error) {
      setIsLoading(false);
      openModal(true, t("thanks.check"));
      setSpinnerVisibility(false);
    }
  };

  const resendEReport = async (id, nombre, email, is_update) => {
    handleResendCandidateClosed();
    if (!is_update) {
      setIsLoading(true);
    }
      let queryParams = `vlid=${id}&nombre=${nombre}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&type=${usuarioCupon}&function=resend&lang=${localStorage.getItem("selectedLanguage") || "es" }&update=${is_update}`;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + "e_report?" + queryParams
      );
      if (response.data == "not found") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else if (response.data == "error bd") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, "Error Occured");
      } else if (response.data == "ok") {
        verEReport(id);
        checkECupons(usuarioCupon, cookies.email);
        setIsLoading(false);
        setSpinnerVisibility(false);
        setIsEmailModal(true);
      }
    } catch (error) {
      setIsLoading(false);
      openModal(true, t("thanks.check"));
      setSpinnerVisibility(false);
    }
  };

  const downloadReport = async (id, curp, email, is_update) => {
    handleResendCandidateClosed();
    if (!is_update) {
      setIsLoading(true);
    }
    let queryParams = `vlid=${id}&curp=${curp}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&type=${usuarioCupon}&function=download&lang=${ localStorage.getItem("selectedLanguage") || "es" }&update=${is_update}`;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + "consult?" + queryParams,
        { responseType: "arraybuffer" }
      );
      if (response.data == "not found") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const timeZone = "America/Mexico_City"; // Replace with the correct time zone identifier
        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat("en", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const formattedDate = formatter.format(currentDate).replace(/:/g, "-");

        const filename = `${curp}_${formattedDate}.pdf`;
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);

        verReport(id);
        checkCupons(usuarioCupon, cookies.email);
        setIsLoading(false);
        setSpinnerVisibility(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, "Some error occured");
    }
  };

  const downloadEReport = async (id, nombre, email, is_update) => {
    handleResendCandidateClosed();
    if (!is_update) {
      setIsLoading(true);
    }
    let queryParams = `vlid=${id}&nombre=${nombre}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&type=${usuarioCupon}&function=download&lang=${localStorage.getItem("selectedLanguage") || "es" }&update=${is_update}`;
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL2 + "e_report?" + queryParams,
        { responseType: "arraybuffer" }
      );
      if (response.data == "not found") {
        setIsLoading(false);
        setSpinnerVisibility(false);
        openModal(true, t("thanks.NoUpdatedDocument"));
      } else {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        const timeZone = "America/Mexico_City"; // Replace with the correct time zone identifier
        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat("en", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        const formattedDate = formatter.format(currentDate).replace(/:/g, "-");

        const filename = `${nombre}_${formattedDate}.pdf`;
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);

        verEReport(id);
        checkECupons(usuarioCupon, cookies.email);
        setIsLoading(false);
        setSpinnerVisibility(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, "Some error occured");
    }
  };

  const handleInfoBoxMessage = () => {
    let messageData = "";
    if (localStorage.getItem("selectedLanguage") == "en") {
      messageData =
        "We apologize, but you have run out of query coupons. Please contact your administrator or purchase more coupons to continue using our services. If you need assistance, call us at +52 55 3332 6533 or email us at contacto@searchlook.mx.";
    } else {
      messageData =
        "Lo sentimos, has agotado tus cupones de consulta. Por favor, contacta a tu administrador o adquiere más cupones para continuar utilizando nuestros servicios. Si necesitas ayuda, llámanos al 55 3332 6533 o escríbenos a contacto@searchlook.mx.";
    }
    return messageData;
  };

  const processCoupons = async () => {
    const res = await checkCupons(usuarioCupon, cookies.email);
    if ((usuarioCupon === "CB" || usuarioCupon === "SB") && res === "error") {
      return "error";
    }
  };

  const processECoupons = async () => {
    const res = await checkECupons(usuarioCupon, cookies.email);
    if (usuarioCupon === "Companies" && res === "error") {
      return "error";
    }
  };

  const updateReport = async (id) => {
    handleResendCandidateClosed();
    setSpinnerVisibility(true);
    let coupon_res = await processCoupons();
    if (coupon_res === "error") {
      setCheckUpdate(true);
      setSpinnerVisibility(false);
      openModal(true, handleInfoBoxMessage);
      return "error";
    }
    let formdata = new FormData();
    formdata.append("sid", id);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "get_dcz",
        formdata
      );
      if ("data" in response) {
        return response.data;
      }
    } catch (e) {
      console.error("Error updating report:", e);
      setSpinnerVisibility(false);
      openModal(true, e);
      return "error";
    }
  };

  const updateEReport = async (id) => {
    handleResendCandidateClosed();
    setSpinnerVisibility(true);
    let coupon_res = await processECoupons();
    if (coupon_res === "error") {
      setCheckUpdate(true);
      setSpinnerVisibility(false);
      openModal(true, handleInfoBoxMessage);
      return "error";
    }
    let formdata = new FormData();
    formdata.append("sid", id);
    try {
      const response = await call_api_function(
        process.env.REACT_APP_BASE_URL2 + "get_dcz_e_module",
        formdata
      );
      if ("data" in response) {
        return response.data;
      }
    } catch (e) {
      console.error("Error updating report:", e);
      setSpinnerVisibility(false);
      openModal(true, e);
      return "error";
    }
  };

  const get_curp_zero = async (datosConsulta) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL2 + "curp_zero",
        datosConsulta,
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data.mensaje === "Guardado correctamente") {
        try {
          await timeSleep();
        } catch (e) {
          setIsLoading(false);
        }
        var init_wait = 1000;
        var i_curpzero = 0;
        var i_flag = false;
        let returnCurpZero;
        while (i_flag === false) {
          returnCurpZero = await checkCurpZero(response.data._id);
          if (returnCurpZero.pending === false) {
            if (returnCurpZero.final_response.status === "1") {
              if (returnCurpZero.final_response.curps._id === "Bloqueado") {
                alert("Bloqueado");
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
            } else if (returnCurpZero.final_response.status === "varios") {
              setIsLoading(false);
            } else if (returnCurpZero.final_response.status === "error") {
              // Borde naranja en inputs
              setIsLoading(false);
            } else {
              // Borde naranja en inputs
              setIsLoading(false);
            }
            i_flag = true;
          } else {
            await timeSleep(init_wait);
          }
          i_curpzero++;
          if (i_curpzero === 5 && i_flag === false) {
            init_wait = 5000;
          }
          if (i_curpzero === 8 && i_flag === false) {
            i_flag = true;
            setIsLoading(false);
            openModal("Maximus number of tries reached");
          }
        }
        return returnCurpZero;
      } else if (
        response.data.mensaje === "Hubo un problema al guardar tu solicitud"
      ) {
        setIsLoading(false);
        openModal(true, "There was a problem saving your request");
      }
    } catch (e) {
      if (e["message"] === "timeout of 10000ms exceeded") {
        setIsLoading(false);
        setTimeout(() => {
          openModal(true, "You reached the time waiting limit Try again later");
        }, 10);
      } else {
        openModal(e);
        setTimeout(() => {
          setIsLoading(false);
          openModal(true, t("thanks.check"));
        }, 10);
      }
    }
  };

  const refreshLegalReview = async (curp, item_id, nbc_id) => {
    try {
      setSpinnerVisibility(true);
      // setIsLoading(true);

      let queryParams = `curp=${curp}&item_id=${item_id}&nbc_id=${nbc_id}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&empresa=${encodeURIComponent(cookies.empresa)}`;
      let response_summ = await axios.get(
        `${process.env.REACT_APP_BASE_URL2}gpt/summary/task/create?${queryParams}&update_review=true`
      );
      let response_hmnm = await axios.get(
        `${process.env.REACT_APP_BASE_URL2}gpt/homonym/task/create?${queryParams}&update_review=true`
      );
      let response_chat = await axios.get(
        `${process.env.REACT_APP_BASE_URL2}gpt/chat/task/create?${queryParams}`
      );
      let summ_updated, hmnm_updated, chat_updated;
      while (true) {
        if (!summ_updated) {
          let resp = await axios.get(
            `${process.env.REACT_APP_BASE_URL2}gpt/summary/task/status?task_id=${response_summ.data.task_id}`
          );
          if (resp.data.status === "done") {
            let formdata = new FormData();
            formdata.append("vlid", item_id);
            formdata.append("type", "vistos_summary");
            await axios.post(
              process.env.REACT_APP_BASE_URL2 + "ver_revision",
              formdata,
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            summ_updated = true;
          }
        }
        if (!hmnm_updated) {
          let resp = await axios.get(
            `${process.env.REACT_APP_BASE_URL2}gpt/homonym/task/status?task_id=${response_hmnm.data.task_id}`
          );
          if (resp.data.status === "done") {
            let formdata = new FormData();
            formdata.append("vlid", item_id);
            formdata.append("type", "vistos_homonym");
            await axios.post(
              process.env.REACT_APP_BASE_URL2 + "ver_revision",
              formdata,
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            hmnm_updated = true;
          }
        }
        if (!chat_updated) {
          let resp = await axios.get(
            `${process.env.REACT_APP_BASE_URL2}gpt/chat/task/status?task_id=${response_chat.data.task_id}`
          );
          if (resp.data.status === "done") {
            let formdata = new FormData();
            formdata.append("vlid", item_id);
            formdata.append("type", "vistos_chat");
            await axios.post(
              process.env.REACT_APP_BASE_URL2 + "ver_revision",
              formdata,
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            chat_updated = true;
          }
        }
        if (summ_updated && hmnm_updated && chat_updated) {
          setIsLoading(false);
          setSpinnerVisibility(false);
          break;
        }
        await timeSleep();
      }
    } catch (error) {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, error);
    }
  };

  const summarizeReport = async (curp, item_id, isUpdateReview = false) => {
    try {
      setSpinnerVisibility(true);
      // setIsLoading(true);

      let queryParams = `curp=${curp}&item_id=${item_id}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&empresa=${encodeURIComponent(cookies.empresa)}`;
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL2}gpt/summary/task/create?${queryParams}&update_review=${isUpdateReview}`
      );
      let taskId = response.data.task_id;
      let n = 0;
      while (true) {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL2}gpt/summary/task/status?task_id=${taskId}`
        );
        if (response.data.status === "done") {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL2}gpt/summary?${queryParams}`
          );
          navigate("/summary", {
            state: { data: response.data, item_id: item_id },
          });
          setIsLoading(false);
          setSpinnerVisibility(false);
          return true;
        }
        await timeSleep();
      }
    } catch (error) {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, error);
    }
  };

  const analyzeHomonimo = async (curp, item_id, isUpdateReview = false) => {
    try {
      setSpinnerVisibility(true);
      // setIsLoading(true);

      let queryParams = `curp=${curp}&item_id=${item_id}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&empresa=${encodeURIComponent(cookies.empresa)}`;
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL2}gpt/homonym/task/create?${queryParams}&update_review=${isUpdateReview}`
      );
      let taskId = response.data.task_id;
      let n = 0;
      while (true) {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL2}gpt/homonym/task/status?task_id=${taskId}`
        );
        if (response.data.status === "done") {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL2}gpt/homonym?${queryParams}`
          );
          navigate("/homonym", {
            state: { data: response.data, item_id: item_id },
          });
          setIsLoading(false);
          setSpinnerVisibility(false);
          return true;
        }
        await timeSleep();
      }
    } catch (error) {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, error);
    }
  };

  const startChat = async (curp, item_id, nbc_id, isNew) => {
    try {
      setSpinnerVisibility(true);
      // setIsLoading(true);

      let queryParams = `curp=${curp}&item_id=${item_id}&nbc_id=${nbc_id}&email=${encodeURIComponent( subUserEmail || cookies.email )}&user_email=${encodeURIComponent(cookies.email)}&empresa=${encodeURIComponent(cookies.empresa)}`;
      if (isNew) {
        let response = await axios.get(
          `${process.env.REACT_APP_BASE_URL2}gpt/chat/task/create?${queryParams}`
        );
        let taskId = response.data.task_id;
        let n = 0;
        while (true) {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL2}gpt/chat/task/status?task_id=${taskId}`
          );
          if (response.data.status === "done") {
            response = await axios.get(
              `${process.env.REACT_APP_BASE_URL2}gpt/chat?new=true&chat_id=${response.data.chat_id}`
            );
            navigate("/chat", {
              state: { data: response.data, curp: curp, item_id: item_id },
            });
            setIsLoading(false);
            setSpinnerVisibility(false);
            return true;
          }
          await timeSleep();
        }
      } else {
        let response = await axios.get(
          `${process.env.REACT_APP_BASE_URL2}gpt/chat?continue=true&${queryParams}`
        );
        navigate("/chat", {
          state: { data: response.data, curp: curp, item_id: item_id },
        });
        setIsLoading(false);
        setSpinnerVisibility(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSpinnerVisibility(false);
      openModal(true, "Sorry an error occurred");
    }
  };

  const encryptDataObject = (datosConsulta) => {
    var keys = getKeysSaltAndIv();
    var userAgent = navigator.userAgent;

    const DataSend = {
      data_sne: AesEncrypt(datosConsulta["sujeto_nombre"], keys.salt, keys.iv),
      data_sap: AesEncrypt(
        datosConsulta["sujeto_apellido_p"],
        keys.salt,
        keys.iv
      ),
      data_sam: AesEncrypt(
        datosConsulta["sujeto_apellido_m"],
        keys.salt,
        keys.iv
      ),
      data_sdo: AesEncrypt(
        datosConsulta["sujeto_dia_nacimiento"],
        keys.salt,
        keys.iv
      ),
      data_smo: AesEncrypt(
        datosConsulta["sujeto_mes_nacimiento"],
        keys.salt,
        keys.iv
      ),
      data_syo: AesEncrypt(
        datosConsulta["sujeto_year_nacimiento"],
        keys.salt,
        keys.iv
      ),
      data_sso: AesEncrypt(datosConsulta["sujeto_sexo"], keys.salt, keys.iv),
      data_scl: AesEncrypt(
        datosConsulta["sujeto_confirmacion_legal"],
        keys.salt,
        keys.iv
      ),
      data_sel: AesEncrypt(datosConsulta["sujeto_email"], keys.salt, keys.iv),
      data_sto: AesEncrypt(datosConsulta["sujeto_tel"], keys.salt, keys.iv),
      data_sce: AesEncrypt(datosConsulta["sujeto_calle"], keys.salt, keys.iv),
      data_sca: AesEncrypt(datosConsulta["sujeto_colonia"], keys.salt, keys.iv),
      data_smu: AesEncrypt(
        datosConsulta["sujeto_municipio"],
        keys.salt,
        keys.iv
      ),
      data_scp: AesEncrypt(
        datosConsulta["sujeto_codigo_postal"],
        keys.salt,
        keys.iv
      ),
      data_seo: AesEncrypt(datosConsulta["sujeto_estado"], keys.salt, keys.iv),
      data_sen: AesEncrypt(
        datosConsulta["sujeto_edo_nacimiento"],
        keys.salt,
        keys.iv
      ),
      data_sfl: AesEncrypt(
        datosConsulta["sujeto_filtro_legal"],
        keys.salt,
        keys.iv
      ),
      data_une: AesEncrypt(datosConsulta["usuario_nombre"], keys.salt, keys.iv),
      data_uap: AesEncrypt(
        datosConsulta["usuario_apellido_p"],
        keys.salt,
        keys.iv
      ),
      data_uam: AesEncrypt(
        datosConsulta["usuario_apellido_m"],
        keys.salt,
        keys.iv
      ),
      data_uel: AesEncrypt(datosConsulta["usuario_email"], keys.salt, keys.iv),
      data_uto: AesEncrypt(datosConsulta["usuario_tel"], keys.salt, keys.iv),
      data_uid: AesEncrypt(datosConsulta["usuario_id"], keys.salt, keys.iv),
      data_ucn: AesEncrypt(datosConsulta["usuario_cupon"], keys.salt, keys.iv),
      data_uta: AesEncrypt(datosConsulta["usuario_tjt"], keys.salt, keys.iv), // FIXME Cambiar si se utiliza cupón
      data_umr: AesEncrypt(datosConsulta["usuario_master"], keys.salt, keys.iv), // NOTE Usuario registrado ('1') / Invitado ('0')
      data_umd: AesEncrypt(
        datosConsulta["usuario_master_id"],
        keys.salt,
        keys.iv
      ), // NOTE ObjectId en caso de usuario registrado
      data_ucl: AesEncrypt(
        datosConsulta["usuario_cliente"],
        keys.salt,
        keys.iv
      ),

      data_userAgent: AesEncrypt(userAgent, keys.salt, keys.iv),
      data_esv: setKeysDict(keys.salt, keys.iv),
      secciones_consulta: datosConsulta["secciones_consulta"],
    };
    return DataSend;
  };

  const starHandler = async (id, email, favorite, index, date, subIndex) => {
    setIsLoading(true);
    var dataForm = {
      email: cookies.email,
      id: id,
      favorite: !favorite,
    };
    const response = await serverRequest(
      "post",
      "toggle-favorite",
      dataForm,
      undefined,
      undefined,
      false
    );
    if (isAxiosResponse(response) && response.data) {
      if (response.data["success"]) {
        if (index >= 0 && index < panelList.length) {
          setPanelList((prevPanelList) => {
            const newPanelList = [...prevPanelList];
            newPanelList[index].favorite = !favorite;
            return newPanelList;
          });
          setGroupPanelList((prevGroupedPanelList) => {
            const newGroupedPanelList = { ...prevGroupedPanelList };
            newGroupedPanelList[date][subIndex].favorite = !favorite;
            return newGroupedPanelList;
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          openModal(true, `Unable to set favorite at index ${index}`);
        }
      }
    }
    if (isAxiosError(response) && response.data) {
      setIsLoading(false);
      openModal(true, "Some error occured");
    }
  };
  const updateStarFavoriteItems = () => {
    const favoriteItemsList = panelList1.filter(
      (item) => item.favorite === true
    );
    setPanelList(favoriteItemsList);

    const favoriteItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const favoriteItems = items.filter((item) => item.favorite === true);
        if (favoriteItems.length > 0) {
          acc[date] = favoriteItems;
        }
        return acc;
      },
      {}
    );

    // Update the state with the filtered data
    setGroupPanelList(favoriteItemsListGrouped);
  };
  const showAllItems = () => {
    // Set panelList to the original data
    setPanelList(panelList1);
    setGroupPanelList(groupPanelList1);
    handleSelectAllClick();
  };

  const updateUnstarFavoriteItems = () => {
    const favoriteItemsList = panelList1.filter(
      (item) => item.favorite === false
    );
    setPanelList(favoriteItemsList);
    const unfavoriteItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const unfavoriteItemsListGrouped = items.filter(
          (item) => item.favorite === false
        );
        if (unfavoriteItemsListGrouped.length > 0) {
          acc[date] = unfavoriteItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(unfavoriteItemsListGrouped);
  };
  const updateUnReadItems = () => {
    const favoriteItemsList = panelList1.filter(
      (item) => item.autoriza === false
    );
    setPanelList(favoriteItemsList);

    const unreadItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const unreadItemsListGrouped = items.filter(
          (item) => item.autoriza === false
        );
        if (unreadItemsListGrouped.length > 0) {
          acc[date] = unreadItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(unreadItemsListGrouped);
  };
  const updateReadItems = () => {
    const favoriteItemsList = panelList1.filter(
      (item) => item.autoriza === true
    );
    setPanelList(favoriteItemsList);

    const readItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const readItemsListGrouped = items.filter(
          (item) => item.autoriza === true
        );
        if (readItemsListGrouped.length > 0) {
          acc[date] = readItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(readItemsListGrouped);
  };

  const updateUnseenItems = () => {
    const favoriteItemsList = panelList1.filter(
      (item) => item.autoriza === true
    );
    setPanelList(favoriteItemsList);

    const unseenItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const unseenItemsListGrouped = items.filter(
          (item) => item.vistos_reporte === 0
        );
        if (unseenItemsListGrouped.length > 0) {
          acc[date] = unseenItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(unseenItemsListGrouped);
  };
  const updateRedItems = () => {
    const redItemsList = panelList1.filter((item) => item.color === "red");
    setPanelList(redItemsList);

    const redItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const redItemsListGrouped = items.filter(
          (item) => item.color === "red"
        );
        if (redItemsListGrouped.length > 0) {
          acc[date] = redItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(redItemsListGrouped);
  };
  const updateGreenItems = () => {
    const greenItemsList = panelList1.filter((item) => item.color === "green");
    setPanelList(greenItemsList);

    const greenItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const greenItemsListGrouped = items.filter(
          (item) => item.color === "green"
        );
        if (greenItemsListGrouped.length > 0) {
          acc[date] = greenItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(greenItemsListGrouped);
  };
  const updateYellowItems = () => {
    const yellowItemsList = panelList1.filter(
      (item) => item.color === "yellow"
    );
    setPanelList(yellowItemsList);

    const yellowItemsListGrouped = Object.entries(groupPanelList1).reduce(
      (acc, [date, items]) => {
        const yellowItemsListGrouped = items.filter(
          (item) => item.color === "yellow"
        );
        if (yellowItemsListGrouped.length > 0) {
          acc[date] = yellowItemsListGrouped;
        }
        return acc;
      },
      {}
    );
    setGroupPanelList(yellowItemsListGrouped);
  };
  
  const shouldRenderUpdatedRow = Object?.values(groupPanelList)?.some((items) =>
    items?.some((item) => item?.updated)
  );

  const handleEmailClick = (event, item) => {
    if (item.candidate_document.color === "red" || item.candidate_document.color === "yellow") {
      setShowEmail(true);
      setItemId(item?._id);
      setItemIdNBC(item?._id_NBC);
      setItemGeoloc(item?.geoloc_requested);
      setItemFaceId(item?.faceid_requested);
      setCandidateEmail(item?.email);
    }
    handleIconClick(event);
  };

  const handleEmailClose = () => {
    setShowEmail(false);
    setItemId("");
    setItemIdNBC("");
    setItemGeoloc("");
    setItemFaceId("");
    setCandidateEmail("");
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "50vh",
        background: { sm: "#ffffff", xs: "transparent" },
      }}
      id="t1"
    >
      <CustomSpinner visible={spinnerVisibility} />
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}

      <EmailSuccessfullySent
        isOpen={emailSentModalOpen}
        setEmailSentModalOpen={setEmailSentModalOpen}
        callbackFunction={() => console.log("close")}
      />

      {showEmail && (
        <SendEmailConmponent
          clickLocation={clickLocation}
          handleEmailClose={handleEmailClose}
          forwardEmail={forwardEmail}
          itemId={itemId}
        />
      )}
      {isEmailModal && (
        <ReportEmailSendModal
          open={isEmailModal}
          onClose={() => setIsEmailModal(false)}
        />
      )}
      {isUpdatedModal && (
        <ReportUpdateModal
          open={isUpdatedModal}
          onClose={() => setIsUpdatedModal(false)}
        />
      )}
      {isModalOpen && (
        <TrashModalpanel
          handleclosed={handleclosedDeleteCandidate}
          itemId={itemId}
          deleteCandidate={deleteCandidate}
        />
      )}
      {showResend && (
        <LightBlackComponent
          vistosReporte={vistosReporte}
          updateReport={updateReport}
          clickLocation={clickLocation}
          curp={curp}
          nombre={nombre}
          email={email}
          handleResendCandidateClosed={handleResendCandidateClosed}
          showReport={showReport}
          showEReport={showEReport}
          resendReport={resendReport}
          resendEReport={resendEReport}
          downloadReport={downloadReport}
          downloadEReport={downloadEReport}
          forwardEmail={forwardEmail}
          eForwardEmail={eForwardEmail}
          showAllResendIcon={showAllResendIcon}
          itemId={itemId}
          moduleType={moduleType}
        />
      )}
      {showRevision && (
        <RevisionLegalComponent
          vistosReporte={vistosReporte}
          updateReport={updateReport}
          clickLocation={clickLocation}
          handleRevisionLegalClosed={handleRevisionLegalClosed}
          summarizeReport={summarizeReport}
          analyzeHomonimo={analyzeHomonimo}
          startChat={startChat}
          refreshLegalReview={refreshLegalReview}
          showAllRevisionIcon={showAllRevisionIcon}
          curp={curp}
          itemId={itemId}
          itemIdNBC={itemIdNBC}
          vistosRevisionSummary={vistosRevisionSummary}
          vistosRevisionHomonym={vistosRevisionHomonym}
          vistosRevisionChat={vistosRevisionChat}
          moduleType={moduleType}
        />
      )}
      {showFaceGeo && (
        <FaceIDGeoLocComponent
          clickLocation={clickLocation}
          forwardEmail={forwardEmail}
          handleFaceGeoClosed={handleFaceGeoClosed}
          currentItem={currentItem}
        />
      )}
      <LogoModalLoader isOpenLogoModal={isLoading} />
      <Box
        sx={{
          display: {
            sm: "block",
            lg: "block",
            xl: "block",
            md: "block",
            xs: "none",
          },
        }}
      >
        <Table stickyHeader sx={{ minWidth: 1100 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  p: "4px",
                  width: 100,
                  backgroundColor: "rgba(251,235,217,255)",
                }}
              >
                <AuthorizationCheckbox
                  updateYellowItems={updateYellowItems}
                  updateRedItems={updateRedItems}
                  updateGreenItems={updateGreenItems}
                  updateFavoriteItems={updateStarFavoriteItems}
                  showAllItems={showAllItems}
                  updateUnstarFavoriteItems={updateUnstarFavoriteItems}
                  updateUnReadItems={updateUnReadItems}
                  updateReadItems={updateReadItems}
                  updateUnseenItems={updateUnseenItems}
                  selectAllChecked={selectAllChecked}
                  handleCheckboxChange={handleCheckboxChange}
                  handleUnselectAllClick={handleUnselectAllClick}
                />
              </TableCell>
              <TableCell
                sx={{
                  p: 0.5,
                  backgroundColor: "rgba(251,235,217,255)",
                  width: 140,
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("authorizationpanel.rightContent.subtitle.Date & Time")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 1,
                  backgroundColor: "rgba(251,235,217,255)",
                  width: "140px",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("Emodule.table_headers.Name")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 1, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 1, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("authorizationpanel.rightContent.subtitle.Authorization")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 1, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("authorizationpanel.rightContent.subtitle.Report")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 0, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("authorizationpanel.rightContent.subtitle.Verification")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 0, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("authorizationpanel.rightContent.subtitle.AI Module")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 1, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("consultField.Candidate")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ p: 1, backgroundColor: "rgba(251,235,217,255)" }}
                align="center"
              >
                <Typography
                  sx={{
                    color: "#1C212D",
                    fontSize: 16,
                    fontFamily: "Gilroy-Bold",
                    fontWeight: "bold",
                  }}
                >
                  {t("authorizationpanel.rightContent.subtitle.Delete")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shouldRenderUpdatedRow && (
              <TableRow sx={{ backgroundColor: "#FAFCFF" }}>
                <TableCell colSpan={10} align="left" sx={{ width: "100%" }}>
                  {t("thanks.Updated")}
                </TableCell>
              </TableRow>
            )}
            {Object.entries(groupPanelList).map(([date, items], index) => (
              <>
                {items.map((item, subIndex) =>
                  item.updated ? (
                    <TableRow
                      sx={{
                        backgroundColor:
                          item.vistos_reporte == 0 ? "#F4F4F4" : "#FAFCFF",
                        "&:hover": {
                          boxShadow: "4px 2px 10px rgba(0, 0, 0, 0.1)", // Adjust the shadow values as needed
                          backgroundColor: "#fff",
                        },
                      }}
                      key={subIndex}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          width: 100,
                          p: 1,
                          // borderBottom: "solid 1px rgba(27, 32, 44, 0.2)"
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Checkbox {...label} />
                          <IconButton
                            onClick={() =>
                              starHandler(
                                item._id,
                                item.email,
                                item.favorite,
                                index,
                                date,
                                subIndex
                              )
                            }
                            aria-label="capture screenshot"
                          >
                            {item.favorite ? (
                              <StarIcon
                                sx={{ color: "#FF8D00", fontSize: 30 }}
                              />
                            ) : (
                              <StarBorderIcon
                                sx={{ color: "#FF8D00", fontSize: 30 }}
                              />
                            )}
                          </IconButton>
                          <IconButton
                            sx={{
                              background: "transparent !important",
                              cursor: "default",
                            }}
                          >
                            <img
                              src={
                                item?.module_type === "candidato"
                                  ? consultPanel
                                  : emodulePanel
                              }
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 1,
                          // borderBottom: "solid 1px rgba(27, 32, 44, 0.2)"
                        }}
                        align="center"
                      >
                        <Box
                          key={index}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            sx={{
                              color: "text.primary",
                              fontSize: 14,
                              fontFamily: "Gilroy-Medium",
                              fontWeight:
                                item.vistos_reporte == 0 ? "bold" : "",
                            }}
                          >
                            {localStorage.getItem("selectedLanguage") == "en"
                              ? item.date
                              : item.es_date}
                          </Typography>
                          <Typography
                            sx={{
                              color: "text.opacity",
                              fontSize: 14,
                              fontFamily: "Gilroy-Medium",
                              fontWeight:
                                item.vistos_reporte == 0 ? "bold" : "",
                              textAlign: "center",
                            }}
                          >
                            {item.time}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align="center">
                        <Typography
                          sx={{
                            color: "text.primary",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                            fontWeight: item.vistos_reporte == 0 ? "bold" : "",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align="center">
                        <Typography
                          sx={{
                            color: "text.primary",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                            fontWeight: item.vistos_reporte == 0 ? "bold" : "",
                          }}
                        >
                          {item.email}
                        </Typography>
                      </TableCell>
                      <Tooltip
                        title={
                          localStorage.getItem("selectedLanguage") == "es" &&
                          item.auth_tooltip_text
                            ? item.auth_tooltip_text[0]
                            : item.auth_tooltip_text[1]
                        }
                        arrow
                        placement="top"
                      >
                        <TableCell sx={{ p: 1 }} align="center">
                          {item.autoriza == true ? (
                            <Box>
                              <img src={Authorizeimage} alt="icon email send" />
                            </Box>
                          ) : (
                            <Box
                              onClick={(event) =>
                                handleResendCandidate(
                                  item,
                                  date,
                                  event
                                )
                              }
                            >
                              <img
                                src={Authorizeimage}
                                alt="icon email send"
                                onClick={handleIconClick}
                                style={{
                                  filter: "grayscale(100%)",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          )}
                        </TableCell>
                      </Tooltip>
                      {item.autoriza == true ? (
                        <TableCell sx={{ p: 1 }} align="center">
                          <Tooltip
                            title={
                              <React.Fragment>
                                {(localStorage.getItem("selectedLanguage") ==
                                  "es" && item.tooltip_text
                                  ? item.tooltip_text[0]
                                  : item.tooltip_text[1]
                                )
                                  .split("\n")
                                  .map((line, index) => (
                                    <span key={index}>
                                      {line}
                                      {index <
                                        (localStorage.getItem(
                                          "selectedLanguage"
                                        ) == "es" && item.tooltip_text
                                          ? item.tooltip_text[0]
                                          : item.tooltip_text[1]
                                        ).split("\n").length -
                                          1 && <br />}
                                    </span>
                                  ))}
                              </React.Fragment>
                            }
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "102px",
                                height: "42px",
                                borderRadius: "45px",
                                justifyContent: "center",
                                // backgroundColor: "#f6cbcf",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={
                                    item.color === "green"
                                      ? reportimggreen
                                      : item.color === "yellow"
                                      ? reportimgyellow
                                      : item.color === "red"
                                      ? reporticonred
                                      : item.color === "blue"
                                      ? reporticonblue
                                      : reporticonblue
                                  }
                                  alt="icon email send"
                                  onClick={(event) =>
                                    handleReportCandidate(
                                      event,
                                      item
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                  color: "#1C212D",
                                  font: "normal normal medium 12px/24px Gilroy",
                                  letterSpacing: "0px",
                                  opacity: "1",
                                }}
                              >
                                {item.usuario_cupon === "SB"
                                  ? "(SB)"
                                  : item.usuario_cupon === "CB"
                                  ? "(CB)"
                                  : ""}
                              </div>
                            </div>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell sx={{ p: 1 }} align="center">
                          <Tooltip
                            title={
                              localStorage.getItem("selectedLanguage") == "es"
                                ? "No autorizado"
                                : "Unauthorized"
                            }
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "102px",
                                height: "42px",
                                borderRadius: "45px",
                                justifyContent: "center",
                                // backgroundColor: '#d7eed4'
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  style={{ filter: "grayscale(100%)" }}
                                  src={reporticonblue}
                                  alt="icon email send"
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                  color: "#1C212D",
                                  font: "normal normal medium 12px/24px Gilroy",
                                  letterSpacing: "0px",
                                  opacity: "1",
                                }}
                              >
                                {item.usuario_cupon === "SB"
                                  ? "(SB)"
                                  : item.usuario_cupon === "CB"
                                  ? "(CB)"
                                  : ""}
                              </div>
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell sx={{ p: 1 }} align="center">
                        <Tooltip
                          title={"FaceID/Geolocation"}
                          arrow
                          placement="top"
                        >
                          <Box>
                            <img
                              onClick={
                                item.module_type === "empresa"
                                  ? null
                                  : item.geoloc_requested || item.faceid_requested ? (event) =>
                                      handleFaceGeo(
                                        event,
                                        item,
                                      ) : null
                              }
                              src={facegeoiconblue}
                              alt="icon faceid geolocation"
                              style={{
                                cursor:
                                item.geoloc_requested || item.faceid_requested ? "pointer" : "",
                                filter:
                                item.module_type === "empresa" ? "grayscale(100%)" : 
                                item.geoloc_requested || item.faceid_requested ? "" : "grayscale(100%)"
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align="center">
                        <Tooltip
                          title={
                            localStorage.getItem("selectedLanguage") == "es" &&
                            item.legal_tooltip_text
                              ? item.legal_tooltip_text[0]
                              : item.legal_tooltip_text[1]
                          }
                          arrow
                          placement="top"
                        >
                          <Box>
                            <img
                              onClick={
                                item.vistos_reporte == 0 || item.module_type === "empresa"
                                  ? null
                                  : (event) =>
                                      handleRevisionLegal(
                                        event,
                                        item
                                      )
                              }
                              src={referenceIcon}
                              alt="referenceIcon"
                              style={{
                                cursor:
                                  item.vistos_reporte == 0 ? "" : "pointer",
                                filter:
                                  item.vistos_reporte == 0 || item.module_type === "empresa"
                                    ? "grayscale(100%)"
                                    : "",
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </TableCell>
                      {item.autoriza == true && item.module_type === "candidato" ? (
                        <TableCell sx={{ p: 1 }} align="center">
                          {item.candidate_document.color === "yellow" ||
                          item.candidate_document.color === "red" ? (
                            <Tooltip
                              title={
                                (item.candidate_document.color === "yellow" ||
                                  item.candidate_document.color === "red") && (
                                  <>
                                    {Object.keys(
                                      item.candidate_document.documents
                                    )
                                      .filter(
                                        (doc) =>
                                          !item.candidate_document.documents[
                                            doc
                                          ]
                                      ) // Filter keys with `false` values
                                      .map((doc, index, filteredDocs) => (
                                        <React.Fragment key={doc}>
                                          {t(`consultField.${doc}`)}
                                          {/* {doc.replace(/_/g, ' ')} */}
                                          {/* Replace underscores with spaces */}
                                          {index < filteredDocs.length - 1 && (
                                            <br />
                                          )}{" "}
                                          {/* Add <br> for all but the last item */}
                                        </React.Fragment>
                                      ))}
                                  </>
                                )
                              }
                              arrow
                              placement="top"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "102px",
                                  height: "42px",
                                  borderRadius: "45px",
                                  justifyContent: "center",
                                  // backgroundColor: "#f6cbcf",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      item.candidate_document.color === "green"
                                        ? greenCircle
                                        : item.candidate_document.color ===
                                          "yellow"
                                        ? yellowCircle
                                        : item.candidate_document.color ===
                                          "red"
                                        ? redCircle
                                        : item.candidate_document.color ===
                                          "blue"
                                        ? greyCircle
                                        : greyCircle
                                    }
                                    alt="icon email send"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                      handleEmailClick(
                                        event,
                                        item
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Tooltip>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  width: "102px",
                                  height: "42px",
                                  borderRadius: "45px",
                                  justifyContent: "center",
                                  // backgroundColor: "#f6cbcf",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      item.candidate_document.color === "green"
                                        ? greenCircle
                                        : item.candidate_document.color ===
                                          "yellow"
                                        ? yellowCircle
                                        : item.candidate_document.color ===
                                          "red"
                                        ? redCircle
                                        : item.candidate_document.color ===
                                          "blue"
                                        ? greyCircle
                                        : greyCircle
                                    }
                                    alt="icon email send"
                                    // style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell sx={{ p: 1 }} align="center">
                          <Tooltip
                            title={
                              localStorage.getItem("selectedLanguage") ===
                                "es" && item.auth_tooltip_text
                                ? item.auth_tooltip_text[0]
                                : item.auth_tooltip_text[1]
                            }
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "102px",
                                height: "42px",
                                borderRadius: "45px",
                                justifyContent: "center",
                                // backgroundColor: "#f6cbcf",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={greyCircle}
                                  alt="icon email send"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell sx={{ p: 1 }} align="center">
                        <Tooltip
                          title={
                            localStorage.getItem("selectedLanguage") == "es" &&
                            item.del_tooltip_text
                              ? item.del_tooltip_text[0]
                              : item.del_tooltip_text[1]
                          }
                          arrow
                          placement="top"
                        >
                          <Box>
                            <img
                              onClick={() => handleDeleteCandidate(item)}
                              src={trashimage}
                              alt="icon email send"
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ) : null
                )}
              </>
            ))}

            {/* start */}

            {Object.entries(groupPanelList).map(([date, items], index) => (
              <React.Fragment key={index}>
                {items.some(
                  (item, subIndex) =>
                     item.name &&
                    item.date && item.es_date && !item.updated
                ) && (
                  <TableRow sx={{ backgroundColor: "#FAFCFF" }}>
                    <TableCell
                      colSpan={10}
                      align="left"
                      sx={{
                        width: "100%",
                        paddingBottom: "0px",
                        paddingTop: "8px",
                      }}
                    >
                      {date}
                    </TableCell>
                  </TableRow>
                )}

                {items.map((item, subIndex) =>
                   item.name &&
                  item.date && item.es_date && !item.updated ? (
                    <TableRow
                      sx={{
                        backgroundColor:
                          item.vistos_reporte == 0 ? "#F4F4F4" : "#FAFCFF",
                        "&:hover": {
                          boxShadow: "4px 2px 10px rgba(0, 0, 0, 0.1)", // Adjust the shadow values as needed
                          backgroundColor: "#fff",
                        },
                      }}
                      key={subIndex}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          width: 100,
                          padding: "3px",

                          // borderBottom: "solid 1px rgba(27, 32, 44, 0.2)"
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Checkbox
                            {...label}
                            checked={selectedCheckboxes.includes(item._id)}
                            onChange={(event) =>
                              handleRowCheckboxChange(event, item._id)
                            }
                          />
                          <IconButton
                            onClick={() =>
                              starHandler(
                                item._id,
                                item.email,
                                item.favorite,
                                index,
                                date,
                                subIndex
                              )
                            }
                            aria-label="capture screenshot"
                          >
                            {item.favorite ? (
                              <StarIcon
                                sx={{ color: "#FF8D00", fontSize: 30 }}
                              />
                            ) : (
                              <StarBorderIcon
                                sx={{ color: "#FF8D00", fontSize: 30 }}
                              />
                            )}
                          </IconButton>
                          <IconButton
                            sx={{
                              background: "transparent !important",
                              cursor: "default",
                            }}
                          >
                            <img
                              src={
                                item?.module_type === "candidato"
                                  ? consultPanel
                                  : emodulePanel
                              }
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: "3px",
                          // borderBottom: "solid 1px rgba(27, 32, 44, 0.2)"
                        }}
                        align="center"
                      >
                        <Box
                          key={index}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            sx={{
                              color: "text.primary",
                              fontSize: 14,
                              fontFamily: "Gilroy-Medium",
                              fontWeight:
                                item.vistos_reporte == 0 ? "bold" : "",
                            }}
                          >
                            {localStorage.getItem("selectedLanguage") == "en"
                              ? item.date
                              : item.es_date}
                          </Typography>
                          <Typography
                            sx={{
                              color: "text.opacity",
                              fontSize: 14,
                              fontFamily: "Gilroy-Medium",
                              fontWeight:
                                item.vistos_reporte == 0 ? "bold" : "",
                              textAlign: "center",
                            }}
                          >
                            {item.time}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align="center">
                        <Typography
                          sx={{
                            color: "text.primary",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                            fontWeight: item.vistos_reporte == 0 ? "bold" : "",
                          }}
                        >
                          {/* {item.candidate_name} {item.candidate_lastname}{" "}
                          {item.candidate_mothername} */}
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align="center">
                        <Typography
                          sx={{
                            color: "text.primary",
                            fontSize: 14,
                            fontFamily: "Gilroy-Medium",
                            fontWeight: item.vistos_reporte == 0 ? "bold" : "",
                          }}
                        >
                          {item.email}
                        </Typography>
                      </TableCell>
                      <Tooltip
                        title={
                          localStorage.getItem("selectedLanguage") == "es" &&
                          item.auth_tooltip_text
                            ? item.auth_tooltip_text[0]
                            : item.auth_tooltip_text[1]
                        }
                        arrow
                        placement="top"
                      >
                        <TableCell sx={{ p: 1 }} align="center">
                          {item.autoriza == true ? (
                            <Box>
                              <img src={Authorizeimage} alt="icon email send" />
                            </Box>
                          ) : (
                            <Box
                              onClick={(event) =>
                                handleResendCandidate(
                                  item,
                                  date,
                                  event
                                )
                              }
                            >
                              <img
                                src={Authorizeimage}
                                alt="icon email send"
                                onClick={handleIconClick}
                                style={{
                                  filter: "grayscale(100%)",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          )}
                        </TableCell>
                      </Tooltip>

                      {item.autoriza == true ? (
                        <TableCell sx={{ p: 1 }} align="center">
                          <Tooltip
                            title={
                              <React.Fragment>
                                {(localStorage.getItem("selectedLanguage") ==
                                  "es" && item.tooltip_text
                                  ? item.tooltip_text[0]
                                  : item.tooltip_text[1]
                                )
                                  .split("\n")
                                  .map((line, index) => (
                                    <span key={index}>
                                      {line}
                                      {index <
                                        (localStorage.getItem(
                                          "selectedLanguage"
                                        ) == "es" && item.tooltip_text
                                          ? item.tooltip_text[0]
                                          : item.tooltip_text[1]
                                        ).split("\n").length -
                                          1 && <br />}
                                    </span>
                                  ))}
                              </React.Fragment>
                            }
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "102px",
                                height: "42px",
                                borderRadius: "45px",
                                justifyContent: "center",
                                // backgroundColor: "#f6cbcf",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    src={
                                      item.color === "green"
                                        ? reportimggreen
                                        : item.color === "yellow"
                                        ? reportimgyellow
                                        : item.color === "red"
                                        ? reporticonred
                                        : item.color === "blue"
                                        ? reporticonblue
                                        : reporticonblue
                                    }
                                    alt="icon email send"
                                    onClick={(event) => 
                                      handleReportCandidate(
                                        event,
                                        item
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />

                                  {item?.new_report && (
                                    <img
                                      src={indicateIcon}
                                      style={{
                                        position: "absolute",
                                        top: "-6px",
                                        right: "-4px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                  color: "#1C212D",
                                  font: "normal normal medium 12px/24px Gilroy",
                                  letterSpacing: "0px",
                                  opacity: "1",
                                }}
                              >
                                {item.usuario_cupon === "SB"
                                  ? "(SB)"
                                  : item.usuario_cupon === "CB"
                                  ? "(CB)"
                                  : ""}
                              </div>
                            </div>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell sx={{ p: 1 }} align="center">
                          <Tooltip
                            title={
                              localStorage.getItem("selectedLanguage") == "es"
                                ? "No autorizado"
                                : "Unauthorized"
                            }
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "102px",
                                height: "42px",
                                borderRadius: "45px",
                                justifyContent: "center",
                                // backgroundColor: '#d7eed4'
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    style={{ filter: "grayscale(100%)" }}
                                    src={reporticonblue}
                                    alt="icon email send"
                                  />

                                  {item?.new_report && (
                                    <img
                                      src={indicateIcon}
                                      style={{
                                        position: "absolute",
                                        top: "-6px",
                                        right: "-4px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "10px",
                                  color: "#1C212D",
                                  font: "normal normal medium 12px/24px Gilroy",
                                  letterSpacing: "0px",
                                  opacity: "1",
                                }}
                              >
                                {item.usuario_cupon === "SB"
                                  ? "(SB)"
                                  : item.usuario_cupon === "CB"
                                  ? "(CB)"
                                  : ""}
                              </div>
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell sx={{ p: 1 }} align="center">
                        <Tooltip
                          title={"FaceID/Geolocation"}
                          arrow
                          placement="top"
                        >
                          <Box>
                            <img
                              onClick={
                                item.module_type === "empresa"
                                  ? null
                                  : item.geoloc_requested || item.faceid_requested ? (event) =>
                                      handleFaceGeo(
                                        event,
                                        item,
                                      ) : null
                              }
                              src={facegeoiconblue}
                              alt="icon faceid geolocation"
                              style={{
                                cursor:
                                item.geoloc_requested || item.faceid_requested ? "pointer" : "",
                                filter:
                                item.module_type === "empresa" ? "grayscale(100%)" : 
                                item.geoloc_requested || item.faceid_requested ? "" : "grayscale(100%)"
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ p: 1 }} align="center">
                        <Tooltip
                          title={
                            localStorage.getItem("selectedLanguage") === "es" &&
                            item.legal_tooltip_text
                              ? item.legal_tooltip_text[0]
                              : item.legal_tooltip_text[1]
                          }
                          arrow
                          placement="top"
                        >
                          <Box>
                            <img
                              onClick={
                                item.vistos_reporte == 0
                                  ? null
                                  : (event) =>
                                      handleRevisionLegal(
                                        event,
                                        item
                                      )
                              }
                              src={islIcon}
                              alt="islIcon"
                              style={{
                                cursor:
                                  item.vistos_reporte == 0 ? "" : "pointer",
                                filter:
                                  item.vistos_reporte == 0
                                    ? "grayscale(100%)"
                                    : "",
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </TableCell>
                      {item.autoriza == true && item.module_type === "candidato" ? (
                        <TableCell sx={{ p: 1 }} align="center">
                          {item.candidate_document.color === "yellow" ||
                          item.candidate_document.color === "red" ? (
                            <Tooltip
                              title={
                                (item.candidate_document.color === "yellow" ||
                                  item.candidate_document.color === "red") && (
                                  <>
                                    {Object.keys(
                                      item.candidate_document.documents
                                    )
                                      .filter(
                                        (doc) =>
                                          !item.candidate_document.documents[
                                            doc
                                          ]
                                      ) // Filter keys with `false` values
                                      .map((doc, index, filteredDocs) => (
                                        <React.Fragment key={doc}>
                                          {t(`consultField.${doc}`)}
                                          {/* {doc.replace(/_/g, ' ')} */}
                                          {/* Replace underscores with spaces */}
                                          {index < filteredDocs.length - 1 && (
                                            <br />
                                          )}{" "}
                                          {/* Add <br> for all but the last item */}
                                        </React.Fragment>
                                      ))}
                                  </>
                                )
                              }
                              arrow
                              placement="top"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "102px",
                                  height: "42px",
                                  borderRadius: "45px",
                                  justifyContent: "center",
                                  // backgroundColor: "#f6cbcf",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      item.candidate_document.color === "green"
                                        ? greenCircle
                                        : item.candidate_document.color ===
                                          "yellow"
                                        ? yellowCircle
                                        : item.candidate_document.color ===
                                          "red"
                                        ? redCircle
                                        : item.candidate_document.color ===
                                          "blue"
                                        ? greyCircle
                                        : greyCircle
                                    }
                                    alt="icon email send"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                      handleEmailClick(
                                        event,
                                        item
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Tooltip>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  width: "102px",
                                  height: "42px",
                                  borderRadius: "45px",
                                  justifyContent: "center",
                                  // backgroundColor: "#f6cbcf",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      item.candidate_document.color === "green"
                                        ? greenCircle
                                        : item.candidate_document.color ===
                                          "yellow"
                                        ? yellowCircle
                                        : item.candidate_document.color ===
                                          "red"
                                        ? redCircle
                                        : item.candidate_document.color ===
                                          "blue"
                                        ? greyCircle
                                        : greyCircle
                                    }
                                    alt="icon email send"
                                    // style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell sx={{ p: 1 }} align="center">
                          <Tooltip
                            title={
                              localStorage.getItem("selectedLanguage") ===
                                "es" && item.auth_tooltip_text
                                ? item.auth_tooltip_text[0]
                                : item.auth_tooltip_text[1]
                            }
                            arrow
                            placement="top"
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "102px",
                                height: "42px",
                                borderRadius: "45px",
                                justifyContent: "center",
                                // backgroundColor: "#f6cbcf",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={greyCircle}
                                  alt="icon email send"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}

                      <TableCell sx={{ p: 1 }} align="center">
                        <Tooltip
                          title={
                            localStorage.getItem("selectedLanguage") == "es" &&
                            item.del_tooltip_text
                              ? item.del_tooltip_text[0]
                              : item.del_tooltip_text[1]
                          }
                          arrow
                          placement="top"
                        >
                          <Box>
                            <img
                              onClick={() => handleDeleteCandidate(item)}
                              src={trashimage}
                              alt="icon email send"
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ) : null
                )}
              </React.Fragment>
            ))}
            {/* end */}
          </TableBody>
        </Table>
      </Box>

      <Box
        sx={{
          width: 1,
          display: {
            sm: "none",
            lg: "none",
            xl: "none",
            md: "none",
            xs: "block",
          },
          background: "#FAFCFF",
          border: "none",
        }}
      >
        {Object.entries(groupPanelList).map(([date, items], index) => (
          <Box key={date} sx={{ background: "transparent" }}>
            {items.some(
              (item, subIndex) =>
                item.name &&
                item.date && item.es_date && !item.updated
            ) && <Box key={date}>{""}</Box>}
            {items.map((value, subIndex) =>
              value.name &&
              value.date && value.es_date ? (
                /* && !value.updated  */
                <Card
                  sx={{
                    width: 1,
                    marginTop: "12px",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 15px 42px #00000008",
                    borderRadius: "6px",
                    padding: "8px 8px 0px 8px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: "10px" }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Checkbox iconStyle={{ fill: "white" }} />
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="p"
                            sx={{
                              color: "#1C212D",
                              fontSize: 14,
                              fontFamily: "Gilroy-Medium",
                              textTransform: "capitalize",
                              marginBottom: "-5px",
                              fontWeight:
                                value.vistos_reporte == 0 ? "bold" : "",
                            }}
                          >
                            {value?.name}
                          </Typography>
                          <IconButton
                            sx={{ paddingBlock: 0 }}
                            onClick={() =>
                              starHandler(
                                value._id,
                                value.email,
                                value.favorite,
                                index,
                                date,
                                subIndex
                              )
                            }
                          >
                            {value?.favorite ? (
                              <StarIcon
                                sx={{ color: "#FF8D00", fontSize: 18 }}
                              />
                            ) : (
                              <StarBorderIcon
                                sx={{ color: "#FF8D00", fontSize: 18 }}
                              />
                            )}
                          </IconButton>
                        </Box>
                        <Typography
                          variant="p"
                          sx={{
                            color: "#1C212D",
                            fontSize: 12,
                            fontFamily: "Gilroy-Medium",
                            fontWeight: value.vistos_reporte == 0 ? "bold" : "",
                          }}
                        >
                          {value?.email}
                        </Typography>
                        <IconButton
                          sx={{
                            background: "transparent !important",
                            cursor: "default",
                          }}
                        >
                          <img
                            src={
                              value?.module_type === "candidato"
                                ? consultPanel
                                : emodulePanel
                            }
                            style={{
                              height: "18px",
                              width: "18px",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Stack>
                    <Box direction="row" alignItems="center" gap="5px">
                      <Typography
                        variant="p"
                        sx={{
                          color: "#1C212D",
                          fontSize: 14,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                          fontWeight: value.vistos_reporte == 0 ? "bold" : "",
                        }}
                      >
                        {localStorage.getItem("selectedLanguage") == "en"
                          ? value.date
                          : value.es_date}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#1C212D",
                          fontSize: 12,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                          opacity: 0.54,
                          fontWeight: value.vistos_reporte == 0 ? "bold" : "",
                        }}
                      >
                        {value.time}
                      </Typography>
                    </Box>
                  </Stack>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    {value?.autoriza == true ? (
                      <Box
                        onClick={(event) =>
                          handleResendCandidate(
                            value,
                            date,
                            event
                          )
                        }
                        sx={{ textAlign: "center" }}
                      >
                        <img
                          src={Authorizeimage}
                          alt="icon email send"
                          width={19}
                          height={19}
                          onClick={handleIconClick}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#1C212D",
                            fontSize: 10,
                            fontFamily: "Gilroy-Medium",
                            textTransform: "capitalize",
                            lineHeight: "8px",
                          }}
                        >
                          {t(
                            "authorizationpanel.rightContent.subtitle.Authorization"
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={Authorizeimage}
                          alt="icon email send"
                          width={19}
                          height={19}
                          style={{
                            filter: "grayscale(100%)",
                            cursor: "pointer",
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#1C212D",
                            fontSize: 10,
                            fontFamily: "Gilroy-Medium",
                            textTransform: "capitalize",
                            lineHeight: "8px",
                          }}
                        >
                          {t(
                            "authorizationpanel.rightContent.subtitle.Authorization"
                          )}
                        </Typography>
                      </Box>
                    )}
                    {value?.autoriza == true ? (
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={
                            value.color === "green"
                              ? reportimggreen
                              : value.color === "yellow"
                              ? reportimgyellow
                              : value.color === "red"
                              ? reporticonred
                              : value.color === "blue"
                              ? reporticonblue
                              : reporticonblue
                          }
                          alt="icon email send"
                          width={19}
                          height={19}
                          onClick={(event) => 
                            handleReportCandidate(
                              event,
                              value
                            )
                          }
                        />
                        <Typography
                          variant="P"
                          sx={{
                            color: "#1C212D",
                            fontSize: 10,
                            fontFamily: "Gilroy-Medium",
                            textTransform: "capitalize",
                            lineHeight: "8px",
                            verticalAlign: "text-top",
                            marginInlineStart: "5px",
                          }}
                        >
                          {value.usuario_cupon === "SB"
                            ? "(SB)"
                            : value.usuario_cupon === "CB"
                            ? "(CB)"
                            : ""}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#1C212D",
                            fontSize: 10,
                            fontFamily: "Gilroy-Medium",
                            textTransform: "capitalize",
                            lineHeight: "8px",
                          }}
                        >
                          {t("authorizationpanel.rightContent.subtitle.Report")}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          style={{ filter: "grayscale(100%)" }}
                          src={reporticonblue}
                          alt="icon email send"
                          width={19}
                          height={19}
                        />
                        <Typography
                          variant="P"
                          sx={{
                            color: "#1C212D",
                            fontSize: 10,
                            fontFamily: "Gilroy-Medium",
                            textTransform: "capitalize",
                            lineHeight: "8px",
                            verticalAlign: "text-top",
                            marginInlineStart: "5px",
                          }}
                        >
                          {value.usuario_cupon === "SB"
                            ? "(SB)"
                            : value.usuario_cupon === "CB"
                            ? "(CB)"
                            : ""}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#1C212D",
                            fontSize: 10,
                            fontFamily: "Gilroy-Medium",
                            textTransform: "capitalize",
                            lineHeight: "8px",
                          }}
                        >
                          {t("authorizationpanel.rightContent.subtitle.Report")}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ textAlign: "center" }}>
                      <img
                        onClick={
                          value.module_type === "empresa"
                            ? null
                            : value.geoloc_requested || value.faceid_requested ? (event) =>
                                handleFaceGeo(
                                  event,
                                  value,
                                ) : null
                        }
                        src={facegeoiconblue}
                        alt="icon faceid geolocation"
                        style={{
                          cursor:
                          value.geoloc_requested || value.faceid_requested ? "pointer" : "",
                          filter:
                          value.module_type === "empresa" ? "grayscale(100%)" : 
                          value.geoloc_requested || value.faceid_requested ? "" : "grayscale(100%)"
                        }}
                        width={19}
                        height={19}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#1C212D",
                          fontSize: 10,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                          lineHeight: "8px",
                        }}
                      >
                        {t(
                          "authorizationpanel.rightContent.subtitle.Verification"
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <img
                        src={islIcon}
                        alt="icon email send"
                        width={19}
                        height={19}
                        style={{
                          cursor: value.vistos_reporte == 0 ? "" : "pointer",
                          filter:
                            value.vistos_reporte == 0 ? "grayscale(100%)" : "",
                        }}
                        onClick={
                          value.vistos_reporte == 0
                            ? null
                            : (event) =>
                                handleRevisionLegal(
                                  event,
                                  value
                                )
                        }
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#1C212D",
                          fontSize: 10,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                          lineHeight: "8px",
                        }}
                      >
                        {t(
                          "authorizationpanel.rightContent.subtitle.AI Module"
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }} >
                      {value.autoriza === true && value.module_type === "candidato" ? (
                        value.candidate_document.color === "yellow" ||
                        value.candidate_document.color === "red" ? (
                          <Box
                            onClick={(event) =>
                              handleEmailClick(
                                event,
                                value
                              )
                            }
                          >
                            <img
                              src={
                                value.candidate_document.color === "green"
                                  ? greenCircle
                                  : value.candidate_document.color === "yellow"
                                  ? yellowCircle
                                  : value.candidate_document.color === "red"
                                  ? redCircle
                                  : value.candidate_document.color === "blue"
                                  ? greyCircle
                                  : greyCircle
                              }
                              alt="icon email send"
                              style={{ cursor: "pointer" }}
                              width={19}
                              height={19}
                            />
                          </Box>
                        ) : (
                          <img
                            src={
                              value.candidate_document.color === "green"
                                ? greenCircle
                                : value.candidate_document.color === "yellow"
                                ? yellowCircle
                                : value.candidate_document.color === "red"
                                ? redCircle
                                : value.candidate_document.color === "blue"
                                ? greyCircle
                                : greyCircle
                            }
                            alt="icon email send"
                            width={19}
                            height={19}
                          />
                        )
                      ) : (
                        <img
                          src={greyCircle}
                          alt="icon email send"
                          style={{ cursor: "pointer" }}
                          width={19}
                          height={19}
                        />
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#1C212D",
                          fontSize: 10,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                          lineHeight: "8px",
                        }}
                      >
                        {t("consultField.Candidate")}
                      </Typography>
                    </Box>

                    <Box sx={{ textAlign: "center" }}>
                      <img
                        src={trashimage}
                        onClick={() => handleDeleteCandidate(value)}
                        alt="icon email send"
                        width={19}
                        height={19}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#1C212D",
                          fontSize: 10,
                          fontFamily: "Gilroy-Medium",
                          textTransform: "capitalize",
                          lineHeight: "8px",
                        }}
                      >
                        {t("authorizationpanel.rightContent.subtitle.Delete")}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              ) : null
            )}
          </Box>
        ))}
      </Box>
    </TableContainer>
  );
});

export default AuthorizationPanelTable;
