import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fragment } from 'react';
import sucessImage from "../../../assets/images/successfull-email.png"
import { useTranslation } from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  maxWidth:"95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:4
};

const subjectList = [
    "The present subject is found",
  ]

export default function EmailSuccessfullySent(props) {
  const { text="", isOpen, setEmailSentModalOpen, callbackFunction } = props
  const handleClose = () => {
    setEmailSentModalOpen(false);
    if (callbackFunction) { callbackFunction(); }
  }
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box sx={{justifyContent:"center",display:"flex", alignItems:"center"}}>
                <img src={sucessImage} width="137px" height="110px" alt="email icon" />
            </Box>
            <Box sx={{display:"flex",justifyContent:"center", mt:4,flexDirection:"column"}}>
            <Typography component={"h6"} sx={{color:"neutral.900",fontSize:20,fontFamily:"Gilroy-SemiBold",textAlign:"center"}}> {t("thanks.ss")} <Box component={"span"} sx={{color:"primary.main"}}> {t("thanks.ss1")}</Box></Typography>
          
          <Typography
          sx={{
            fontSize:16,
            fontFamily: "Gilroy-Medium",
            color:"rgba(27, 32, 44, 0.6)",
            textAlign:"center",
            mt:2
          }}
          >
         {text}
          </Typography>
          </Box>
         
          <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:4}}>
          <Button onClick={handleClose} sx={{backgroundColor:"primary.main",py:1,px:{xs:4,sm:8},fontSize:"18px",  fontFamily: "Gilroy-Medium",textTransform:"capitalize" }} variant="contained">{t("buttons.back")}</Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}