import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import resetImg from "../../../../src/assets/images/Undo.svg"
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    maxWidth: "95%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

const subjectList = [
    "The present subject is found",
]


const ResetConfirmModal = (props) => {
    const { isOpen, setIsOpen, handleReset } = props
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setIsOpen(false);
    }
    const { t } = useTranslation();
    const sendHandler = () => {
        setIsOpen(false)
        handleReset();
    };
    return (
        <>
            <Modal
                open={isOpen}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                        <img src={resetImg} width="77px" height="77px" alt="email icon" />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4, flexDirection: "column" }}>
                        <Typography component={"h6"} sx={{ color: "neutral.900", fontSize: 20, fontFamily: "Gilroy-SemiBold", textAlign: "center" }}>{t("thanks.reset 1")}<Box component={"span"}> {t("thanks.reset 2")} </Box></Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 4 }}>
                        <Button onClick={handleClose} sx={{ backgroundColor: "#A7A6A6 !important", py: 1, px: { xs: 4, sm: 8 }, fontSize: "18px", fontFamily: "Gilroy-Medium", textTransform: "capitalize" }} variant="contained">NO</Button>
                        <Button onClick={sendHandler} sx={{ backgroundColor: "primary.main", py: 1, px: { xs: 4, sm: 8 }, fontSize: "18px", fontFamily: "Gilroy-Medium", textTransform: "capitalize" }} variant="contained">OK</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ResetConfirmModal