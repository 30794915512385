export const sectionAData = {
  I: {
    titlealphabet: "A",
    maintitle: "Tabs.A",
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.I",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Colony",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Colonys",
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Colony",
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.GeoLocation",
    ],
    format: "colonia",
    by: {
      status: true,
      value: "Residencia_Colonia",
    },
    inner_table: {
      status: true,
      data: "Geo_Location",
      type: "geoloc",
    },
    sub: "sub_a1",
  },
  II: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.II",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Estimated",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Amount MXN",
    ],
    format: "Renta_Mensual_Estimada",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a2",
  },
  III: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.III",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.People",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Amount, No / ◉ Relationship",
    ],
    format: "Circulo_Social",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Circulo_Social_Arreglo",
      type: "family",
    },
    sub: "sub_a3",
  },
  IV: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.IV",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.IMSS / ISSSTE",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Relationship Father",
    ],
    format: "Dependientes",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Dependientes_Arreglo",
      type: "beneficiaries",
    },
    sub: "sub_a4",
  },
  V: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.V",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Institution",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Name of institution",
    ],
    format: "Universidad",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a5",
  },
  VI: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.VI",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Accreditation",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.ID/Title",
    ],
    format: "Cedula",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a6",
  },
  VII: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.VII",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Last",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Doctorate",
    ],
    format: "Nivel_Estudios",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a7",
  },
  VIII: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.VIII",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.National",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Certificate",
    ],
    format: "certificados",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a8",
  },
  IX: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.IX",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.SEP",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.SEP",
    ],
    format: "Last_Grade",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a9",
  },
  X: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.X",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.INEA",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.INEA",
    ],
    format: "INEA",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a10",
  },
  XI: {
    title:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.A. Socioeconomic Study.XI",
    concepto:
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Concept.Own",
    resultado: [
      "landingpage.leftContent.tabs.A | Socioeconomic Study.Result.Yes",
    ],
    format: "Vivienda_Propia",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_a11",
  },
};

export const sectionBData = {
  I: {
    titlealphabet: "B",
    maintitle: "Tabs.B",
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.I",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Estatus Laboral Ininterrumpido y Duración",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Estatus Laboral Ininterrumpido y Duración",
    ],
    format: "Situacion_Laboral_Actual",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_b1",
  },
  II: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.II",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Último Empleador y Duración",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Último Empleador y Duración",
    ],
    format: "Ultimo_Empleador_Duracion",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_b2",
  },
  III: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.III",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Último Salario Cotizado",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Último Salario Cotizado",
    ],
    format: "Salario_Cotizado",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_b3",
  },
  IV: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.IV",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Vida Laborable Total",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Vida Laborable Total",
    ],
    format: "Tiempo_Laborando",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_b4",
  },
  V: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.V",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Total Semanas Cotizadas",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Total Semanas Cotizadas",
    ],
    format: "Semanas_Cotizadas",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Semanas_Cotizadas_Arreglo",
      type: "listed_weeks",
    },
    sub: "sub_b5",
  },
  VI: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.VI",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Total Empleadores y Duración",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Total Empleadores y Duración",
    ],
    format: "Numero_Empleos",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Numero_Empleos_Arreglo",
      type: "total_employees_and_duration",
    },
    sub: "sub_b6",
  },
  VII: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.VII",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Progresión Salarial",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Progresión Salarial",
    ],
    format: "Progresion_Salarial",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Progresion_Salarial_Arreglo",
      type: "salary",
    },
    sub: "sub_b7",
  },
  VIII: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.VIII",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Periodo Inactividad",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Periodo Inactividad",
    ],
    format: "Numero_Despidos",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Numero_Despidos_Arreglo",
      type: "despidos",
    },
    sub: "sub_b8",
  },
  IX: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.IX",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Semanas Descontadas",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Semanas Descontadas",
    ],
    format: "tsc_imss_descontadas",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "tsc_imss_descontadas_arreglo",
      type: "imss",
    },
    sub: "sub_b9",
  },
  X: {
    title:
      "landingpage.leftContent.tabs.B | Employment History.B. Historial Laboral.X",
    concepto:
      "landingpage.leftContent.tabs.B | Employment History.Concept.Problemas Empleador Actual",
    resultado: [
      "landingpage.leftContent.tabs.B | Employment History.Result.Problemas Empleador Actual",
    ],
    format: "Problemas_Empleador",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_b10",
  },
};

export const sectionCData = {
  I: {
    titlealphabet: "C",
    maintitle: "Tabs.C",
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.I",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Laborales",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Laborales",
    ],
    format: "Demandas_Laborales",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Demandas_Laborales_Arreglo",
      type: "labor_lawsuits_settlement",
    },
    sub: "sub_c1",
  },
  II: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.II",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Civiles",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Civiles",
    ],
    format: "Demandas_Civiles",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Demandas_Civiles_Arreglo",
      type: "civil_suits_settlement",
    },
    sub: "sub_c2",
  },
  III: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.III",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Familiares",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Familiares",
    ],
    format: "Demandas_Familiares",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Demandas_Familiares_Arreglo",
      type: "lawsuits_family_settlement",
    },
    sub: "sub_c3",
  },
  IV: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.IV",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Procesos Penales",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Procesos Penales",
    ],
    format: "Demandas_Penales",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Demandas_Penales_Arreglo",
      type: "criminal_lawsuits_settlement",
    },
    sub: "sub_c4",
  },
  V: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.V",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Demandas Administrativas",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Demandas Administrativas",
    ],
    format: "Demandas_Administrativas",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Demandas_Administrativas_Arreglo",
      type: "administrative_demands_settlement",
    },
    sub: "sub_c5",
  },
  VI: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.VI",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Agresores Sexuales",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Agresores Sexuales",
    ],
    format: "Agresores_Sexuales",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_c6",
  },
  VII: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.VII",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Alertas Internacionales",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Alertas Internacionales",
    ],
    format: "Alertas_Internacionales",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_c7",
  },
  VIII: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.VIII",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.SAT Contribuyente",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.SAT Contribuyente",
    ],
    format: "Dueda_SAT",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_c8",
  },
  IX: {
    title: "thanks.tachometer",
    concepto: "thanks.c-section",
    sub: "sub_c9",
    resultado: [" "],
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
  },
  X: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.X",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Sindicatos",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Sindicatos",
    ],
    format: "Sindicatos_Count",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Sindicatos",
      type: "Sindicatos",
    },
    sub: "sub_c10",
  },
  XI: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.XI",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Funcionarios",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Funcionarios",
    ],
    format: "Funcionarios_Count",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Funcionarios",
      type: "Funcionarios",
    },
    sub: "sub_c11",
  },
  XII: {
    title:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.C. Historial Legal Homónimo.XII",
    concepto:
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Concept.Partidos",
    resultado: [
      "landingpage.leftContent.tabs.C | Historial Legal Homónimo.Result.Partidos",
    ],
    format: "Partidos_Count",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Partidos",
      type: "Partidos",
    },
    sub: "sub_c12",
  },
};

export const sectionDData = {
  I: {
    titlealphabet: "D",
    maintitle: "Tabs.D",
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.I",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Fecha Primer Crédito y Antigüedad",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Fecha Primer Crédito y Antigüedad",
    ],
    format: "Tiempo_Creditos",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "Demandas_Laborales_Arreglo",
      type: "labor_lawsuits_settlement",
    },
    sub: "sub_d1",
  },
  II: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.II",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Nº Créditos Activos Total",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Nº Créditos Activos Total",
    ],
    format: "Numero_creditos_total",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_d2",
  },
  III: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.III",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Tipo Créditos Activos",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Tipo Créditos Activos",
    ],
    format: "Tipo_Deuda",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Tipo_Deuda_Arreglo",
      type: "deuda",
    },
    sub: "sub_d3",
  },
  IV: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.IV",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Pagos Mensuales",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Pagos Mensuales",
    ],
    format: "Pagos_Mensuales",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Pagos_Mensuales_Arreglo",
      type: "mensuales",
    },
    sub: "sub_d4",
  },
  V: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.V",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Crédito Máximo Autorizado",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Crédito Máximo Autorizado",
    ],
    format: "Credito_Maximo",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Credito_Maximo_Arreglo",
      type: "maximo",
    },
    sub: "sub_d5",
  },
  VI: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.VI",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Historial Pago",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Historial Pago",
    ],
    format: "Historial_Pago",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Historial_Pago_Arreglo",
      type: "historical_pago",
    },
    sub: "sub_d6",
  },
  VII: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.VII",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Nivel Endeudamiento Total",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Nivel Endeudamiento Total",
    ],
    format: "Endeudamiento_Total",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_d7",
  },
  VIII: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.VIII",
    concepto:
      "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Nivel Endeudamiento sin Hipoteca",
    resultado: [
      "landingpage.leftContent.tabs.D | Análisis Deuda.Result.Nivel Endeudamiento sin Hipoteca",
    ],
    format: "Endeudamiento_sin_Hip",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_d8",
  },
  IX: {
    title:
      "landingpage.leftContent.tabs.D | Análisis Deuda.D. Análisis Deuda.IX",
    concepto: "landingpage.leftContent.tabs.D | Análisis Deuda.Concept.Score",
    resultado: ["landingpage.leftContent.tabs.D | Análisis Deuda.Result.Score"],
    format: "scoring",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_d9",
  },
};

export const sectionEData = {
  I: {
    titlealphabet: "E",
    maintitle: "Tabs.E",
    title:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.I",
    concepto:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Estatus Crédito",
    resultado: [
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Estatus Crédito",
    ],
    format: "Mensaje_Hipoteca",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_e1",
  },
  II: {
    title:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.II",
    concepto:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Puntos",
    resultado: [
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Puntos",
    ],
    format: "Puntos_Hipoteca",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_e2",
  },
  III: {
    title:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.III",
    concepto:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Subcuenta Vivienda",
    resultado: [
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Subcuenta Vivienda",
    ],
    format: "SCV_Hipoteca",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_e3",
  },
  IV: {
    title:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.IV",
    concepto:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Préstamo Máximo",
    resultado: [
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Préstamo Máximo",
    ],
    format: "PMaximo_Hipoteca",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_e4",
  },
  V: {
    title:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.E. Crédito Hipotecario.V",
    concepto:
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Concept.Descuento Hipoteca",
    resultado: [
      "landingpage.leftContent.tabs.E | Crédito Hipotecario.Result.Descuento Hipoteca",
    ],
    format: "Descuento_Hipoteca",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_e5",
  },
};
export const sectionFData = {
  I: {
    titlealphabet: "F",
    maintitle: "Tabs.F",
    title:
      "landingpage.leftContent.tabs.F | Información Relevante.F. Información Relevante.I",
    concepto:
      "landingpage.leftContent.tabs.F | Información Relevante.Concept.Derecho Servicio Médico",
    resultado: [
      "landingpage.leftContent.tabs.F | Información Relevante.Result.Derecho Servicio Médico",
    ],
    format: "Derecho_ServicioMedico",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_f1",
  },
  II: {
    title:
      "landingpage.leftContent.tabs.F | Información Relevante.F. Información Relevante.II",
    concepto:
      "landingpage.leftContent.tabs.F | Información Relevante.Concept.Clínica",
    resultado: [
      "landingpage.leftContent.tabs.F | Información Relevante.Result.Clínica",
    ],
    format: "",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: true,
      data: "Clinica_Arreglo",
      type: "clinica_arreglo",
    },
    sub: "sub_f2",
  },
  III: {
    title:
      "landingpage.leftContent.tabs.F | Información Relevante.F. Información Relevante.III",
    concepto:
      "landingpage.leftContent.tabs.F | Información Relevante.Concept.Finiquito",
    resultado: [
      "landingpage.leftContent.tabs.F | Información Relevante.Result.Finiquito",
    ],
    format: "Finiquito",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_f3",
  },
  IV: {
    title:
      "landingpage.leftContent.tabs.F | Información Relevante.F. Información Relevante.IV",
    concepto:
      "landingpage.leftContent.tabs.F | Información Relevante.Concept.Liquidación",
    resultado: [
      "landingpage.leftContent.tabs.F | Información Relevante.Result.Liquidación",
    ],
    format: "Liquidacion",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_f4",
  },
  V: {
    title:
      "landingpage.leftContent.tabs.F | Información Relevante.F. Información Relevante.V",
    concepto:
      "landingpage.leftContent.tabs.F | Información Relevante.Concept.Aguinaldo",
    resultado: [
      "landingpage.leftContent.tabs.F | Información Relevante.Result.Aguinaldo",
    ],
    format: "Aguinaldo",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_f5",
  },
  VI: {
    title:
      "landingpage.leftContent.tabs.F | Información Relevante.F. Información Relevante.VI",
    concepto:
      "landingpage.leftContent.tabs.F | Información Relevante.Concept.Vacaciones",
    resultado: [
      "landingpage.leftContent.tabs.F | Información Relevante.Result.Vacaciones",
    ],
    format: "Vacaciones",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_f6",
  },
};

export const sectionGData = {
  I: {
    titlealphabet: "G",
    maintitle: "Tabs.G",
    title:
      "landingpage.leftContent.tabs.G | Recomendación Final.G. Recomendación Final.I",
    concepto:
      "landingpage.leftContent.tabs.G | Recomendación Final.Concept.Historial Laboral",
    resultado: [
      "landingpage.leftContent.tabs.G | Recomendación Final.Result.Historial Laboral",
    ],
    format: "Historial_laboral_porcentaje",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_g1",
  },
  II: {
    titlealphabet: "G",
    title:
      "landingpage.leftContent.tabs.G | Recomendación Final.G. Recomendación Final.II",
    concepto:
      "landingpage.leftContent.tabs.G | Recomendación Final.Concept.Historial Legal Homónimo",
    resultado: [
      "landingpage.leftContent.tabs.G | Recomendación Final.Result.Historial Legal Homónimo",
    ],
    format: "Historial_Legal_Mensaje",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "Clinica_Arreglo",
      type: "clinica_arreglo",
    },
    sub: "sub_g2",
  },
  III: {
    titlealphabet: "G",
    title:
      "landingpage.leftContent.tabs.G | Recomendación Final.G. Recomendación Final.III",
    concepto:
      "landingpage.leftContent.tabs.G | Recomendación Final.Concept.Recomendaría Prestar",
    resultado: [
      "landingpage.leftContent.tabs.G | Recomendación Final.Result.Recomendaría Prestar",
    ],
    format: "SLcore",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_g3",
  },
  IV: {
    titlealphabet: "G",
    title:
      "landingpage.leftContent.tabs.G | Recomendación Final.G. Recomendación Final.IV",
    concepto:
      "landingpage.leftContent.tabs.G | Recomendación Final.Concept.Promedio Final",
    resultado: [
      "landingpage.leftContent.tabs.G | Recomendación Final.Result.Promedio Final",
    ],
    format: "Comportamiento_Personal_porcentaje",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_g4",
  },
  V: {
    titlealphabet: "G",
    title:
      "landingpage.leftContent.tabs.G | Recomendación Final.G. Recomendación Final.V",
    concepto:
      "landingpage.leftContent.tabs.G | Recomendación Final.Concept.Políticamente Expuestas",
    resultado: [
      "landingpage.leftContent.tabs.G | Recomendación Final.Result.Políticamente Expuestas",
    ],
    format: "Policitamente_expuesto_porcentaje",
    by: {
      status: false,
      value: "",
    },
    inner_table: {
      status: false,
      data: "",
      type: "",
    },
    sub: "sub_g5",
  },
};
