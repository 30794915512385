import { Grid, Typography, Box, MenuItem, Menu } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import underLine from "../../assets/images/authorize-underline.png";
import AuthorizationPanelTable from "./AuthorizationPanelTable";
import Button from "@mui/material/Button";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AuthPanelFilter from './AuthPanelFilter';

import {
  isAxiosResponse,
  isAxiosError,
  serverRequest,
} from "../../utils/requestHelper";
import { getCookiesDict } from "../../utils/cookiesHelper";
import LogoModalLoader from "../@common/Modal/WithBureauModal/LogoModalLoader.js";
import AlertModal from "../@common/Modal/AlertModal";
import { useTranslation } from "react-i18next";
import CardPanelReport from "./CardPanelReport";
import img from "../../assets/images/status-green.svg";
import imgTwo from "../../assets/images/status-error.svg";
import Carousel from 'react-material-ui-carousel';

import { call_api_function } from "../../utils/verifyAPICalls.js";
import {
  formatDate2,
  formatDateSpanishMonth,
  formatTime,
} from "../../utils/dateFormatHelper.js";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';
import AuthorizationPanelSearchBar from "./AuthorizationPanelSearchBar"
import { useGlobalContext } from "../../context.js";


function AuthorizationPanel() {
  const { isUserPanelsVisible } = useGlobalContext();
  const { t } = useTranslation();
  const [initialPanelList, setInitialPanelList] = useState([]);
  const [initialGroupPanelList, setInitialGroupedPanelList] = useState([]);
  const [panelList1, setpanelList1] = useState([]);
  const [panelList, setPanelList] = useState([]);
  const [groupPanelList, setGroupPanelList] = useState([]);
  const [groupPanelList1, setGroupPanelList1] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showtext, setShowtext] = useState("");
  const [cookies, setCookies] = useState(getCookiesDict());
  const [isLoading, setIsLoading] = useState(false);
  const [hasNoMoreItems, setHasNoMoreItems] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [dropdownSelected, setSelectedDropdown] = useState("Emodule.both")
  const [isUpdateClick, setUpdateClicked] = useState(false)
  const [subUserEmail, setSubUserEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [showMore, setShowMore] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuItem) => {
    if (menuItem) {
      setShowMore(false);
      setCurrentPage(1);
      setSearchQuery({consult_type: menuItem});
    }
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const openModal = (flag, errorMsg) => {
    setShowAlert(flag);
    setShowtext(errorMsg);
  };

  const getSummary = async () => {
    const formdata = new FormData();
    formdata.append("email", cookies.email);
    const response = await call_api_function(
      process.env.REACT_APP_BASE_URL2 + "panel-summary",
      formdata
    );
    if (response.data == "error bd") {
    } else {
      setSummaryData(response.data);
    }
  };

  const fetchPanelList = async () => {
    try {
      setIsLoading(true);
      setSpinnerVisibility(true);
      
      searchQuery.user_email = subUserEmail || cookies.email
      searchQuery.page = currentPage
      searchQuery.items_per_page = itemsPerPage

      let response = await serverRequest(
        "post",
        "todos-autorizados",
        searchQuery,
        {},
        600000,
        false
      );
  
      if ("data" in response && Array.isArray(response.data)) {
        const groupedData = response.data.reduce((acc, record) => {
          const date = new Date(record.date).toLocaleDateString(); // Adjust date formatting if needed
          if (!acc[date]) {
            acc[date] = [record];
          } else {
            acc[date].push(record);
          }
          return acc;
        }, {});

        const sortedDates = Object.keys(groupedData).sort(
          (a, b) => new Date(b) - new Date(a)
        );
        if (response.data.length < itemsPerPage) {
          setHasNoMoreItems(true);
        } else {
          setHasNoMoreItems(false);
        }
        setInitialPanelList((existingList) => [
          ...(showMore ? existingList : []),
          ...response.data,
        ]);
        setInitialGroupedPanelList((existingList) => {
          return sortedDates.reduce(
            (mergedData, date) => {
              const updatedData = groupedData[date].map((item) => {
                const updatedDate = formatDate2(item.date);
                const updatedDateSpanish = formatDateSpanishMonth(item.date);
                const updatedTime = formatTime(item.date);

                return {
                  ...item,
                  date: updatedDate,
                  es_date: updatedDateSpanish,
                  time: updatedTime,
                };
              });

              return { ...mergedData, [date]: updatedData };
            },
            { ...(showMore ? existingList : []) }
          );
        });

        // New

        setPanelList((existingList) => [...(showMore ? existingList : []), ...response.data]);
        setpanelList1((existingList) => [...(showMore ? existingList : []), ...response.data]);
        setGroupPanelList((existingList) => {
          return sortedDates.reduce(
            (mergedData, date) => {
              const updatedData = groupedData[date].map((item) => {
                const updatedDate = formatDate2(item.date);
                const updatedDateSpanish = formatDateSpanishMonth(item.date);
                const updatedTime = formatTime(item.date);

                return {
                  ...item,
                  date: updatedDate,
                  es_date: updatedDateSpanish,
                  time: updatedTime,
                };
              });

              return { ...mergedData, [date]: updatedData };
            },
            { ...(showMore ? existingList : []) }
          );
        });

        setGroupPanelList1((existingList) => {
          return sortedDates.reduce(
            (mergedData, date) => {
              const updatedData = groupedData[date].map((item) => {
                const updatedDate = formatDate2(item.date);
                const updatedDateSpanish = formatDateSpanishMonth(item.date);
                const updatedTime = formatTime(item.date);

                return {
                  ...item,
                  date: updatedDate,
                  es_date: updatedDateSpanish,
                  time: updatedTime,
                };
              });

              return { ...mergedData, [date]: updatedData };
            },
            { ...(showMore ? existingList : []) }
          );
        });
      } else {
        openModal(true, "Connection error or invalid data format");
      }
      const lastItemRef = document.getElementById("t1");
      if (lastItemRef) {
        lastItemRef.parentElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } catch (error) {
      console.log(error)
      openModal(true, "An error occurred while fetching data");
    }
    setIsLoading(false);
    setSpinnerVisibility(false);
  };
  const seeMoreHandler = async () => {
    const nextPage = currentPage + 1;
    setShowMore(true);
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    getSummary();
  }, []);

  useEffect(() => {
    fetchPanelList();
  }, [currentPage, searchQuery, subUserEmail]);

  return (
    <>
      {showAlert && <AlertModal text={showtext} onClose={handleCloseAlert} />}
      <LogoModalLoader isOpenLogoModal={isLoading} />
      <Grid container spacing={2} sx={{ mt: -4 }}>
      <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%"
            }}
          >
            <Button
              id="fade-button"
              aria-controls={Boolean(anchorEl) ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                color: "#FF8D28",
                fontFamily: "Gilroy-Regular",
                fontSize: { xs: "20px", sm: "22px" },
                textTransform: "capitalize",
                marginLeft: "16px",
                padding: "3px 3px 0px 15px ",
                backgroundColor: Boolean(anchorEl) ? "rgba(255, 140, 0, 0.1)" : "inherit",
                "&:hover": {
                  backgroundColor: "rgba(255, 140, 0, 0.1)"
                },
                marginTop: {xs:"36px",sm:"16px"}
              }}
            >
              {t(dropdownSelected)}

              <KeyboardArrowDownIcon
                sx={{
                  fontSize: { xs: 26, sm: 28 }
                }}
              />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
              sx={{
                marginTop: "8px",
                "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                  width: { xs: "215px", sm: "215px" },
                  paddingTop: { xs: "0", sm: "8px" },
                  paddingBottom: { xs: "0", sm: "8px" },
                },
                " & .css-19ifamm-MuiButtonBase-root-MuiMenuItem-root": {
                  fontFamily: "Gilroy-Medium",
                  fontSize: "18px",
                }
              }}
            >
              <MenuItem onClick={() => { handleClose('candidato'); setSelectedDropdown("Emodule.currentReports") }}>{t("Emodule.currentReports")}</MenuItem>
              <MenuItem onClick={() => { handleClose('empresa'); setSelectedDropdown("Emodule.emoduleReports") }}>{t("Emodule.emoduleReports")}</MenuItem>
              <MenuItem onClick={() => { handleClose('both'); setSelectedDropdown("Emodule.both") }}>{t("Emodule.both")}</MenuItem>
            </Menu>
          </Box> 
          {/* select Report  */}
        <Carousel sx={{
          display: { sm: 'none', lg: 'none', xl: 'none', md: 'none', xs: 'block' },
          width: 1,
          padding: "10px",
          "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-a2nnnk-MuiButtonBase-root-MuiIconButton-root": {
            color: '#FF8D28',
          },
        }}
          navButtonsAlwaysInvisible="true"
        >
          <CardPanelReport
            title={t("authorizationpanel.summary.reportsPerDay")} none
            count={summaryData.reports_today_count ?? 0}
            countPercentage={
              summaryData.reports_today_percentage_change
                ? summaryData.reports_today_percentage_change + "%"
                : "0%"
            }
            color={
              (summaryData.reports_today_percentage_change ?? 0) >= 0
                ? "#35A826"
                : "#DD0717"
            }
            statusIcon={
              (summaryData.reports_today_percentage_change ?? 0) >= 0
                ? img
                : imgTwo
            }

          />
          <CardPanelReport
            title={t("authorizationpanel.summary.pendingAuthorized")}
            count={summaryData.autorization_summary_count ?? 0}
            countPercentage={
              summaryData.autorization_summary_percentage_change
                ? summaryData.autorization_summary_percentage_change + "%"
                : "0%"
            }
            color={
              (summaryData.autorization_summary_percentage_change ?? 0) >=
                0
                ? "#35A826"
                : "#DD0717"
            }
            statusIcon={
              (summaryData.autorization_summary_percentage_change ?? 0) >=
                0
                ? img
                : imgTwo
            }
          />
          <CardPanelReport
            title={t("authorizationpanel.summary.pendingItems")}
            count={summaryData.vistos_reporte_summary_count ?? 0}
            countPercentage={
              summaryData.vistos_reporte_summary_percentage_change
                ? summaryData.vistos_reporte_summary_percentage_change +
                "%"
                : "0%"
            }
            color={
              (summaryData.vistos_reporte_summary_percentage_change ??
                0) >= 0
                ? "#35A826"
                : "#DD0717"
            }
            statusIcon={
              (summaryData.vistos_reporte_summary_percentage_change ??
                0) >= 0
                ? img
                : imgTwo
            }
          />
        </Carousel>
        <Grid item xs={12} sm={12} sx={{ display: { sm: 'block', lg: 'block', xl: 'block', md: 'block', xs: 'none' } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3.5} xl={3}>
              <CardPanelReport
                title={t("authorizationpanel.summary.reportsPerDay")}
                count={summaryData.reports_today_count ?? 0}
                countPercentage={
                  summaryData.reports_today_percentage_change
                    ? summaryData.reports_today_percentage_change + "%"
                    : "0%"
                }
                color={
                  (summaryData.reports_today_percentage_change ?? 0) >= 0
                    ? "#35A826"
                    : "#DD0717"
                }
                statusIcon={
                  (summaryData.reports_today_percentage_change ?? 0) >= 0
                    ? img
                    : imgTwo
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3.5} xl={3}>
              <CardPanelReport
                title={t("authorizationpanel.summary.pendingAuthorized")}
                count={summaryData.autorization_summary_count ?? 0}
                countPercentage={
                  summaryData.autorization_summary_percentage_change
                    ? summaryData.autorization_summary_percentage_change + "%"
                    : "0%"
                }
                color={
                  (summaryData.autorization_summary_percentage_change ?? 0) >=
                    0
                    ? "#35A826"
                    : "#DD0717"
                }
                statusIcon={
                  (summaryData.autorization_summary_percentage_change ?? 0) >=
                    0
                    ? img
                    : imgTwo
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3.5} xl={3}>
              <CardPanelReport
                title={t("authorizationpanel.summary.pendingItems")}
                count={summaryData.vistos_reporte_summary_count ?? 0}
                countPercentage={
                  summaryData.vistos_reporte_summary_percentage_change
                    ? summaryData.vistos_reporte_summary_percentage_change +
                    "%"
                    : "0%"
                }
                color={
                  (summaryData.vistos_reporte_summary_percentage_change ??
                    0) >= 0
                    ? "#35A826"
                    : "#DD0717"
                }
                statusIcon={
                  (summaryData.vistos_reporte_summary_percentage_change ??
                    0) >= 0
                    ? img
                    : imgTwo
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} sm={12} >
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: { xs: 0, lg: -9 }, display: { xs: "block", lg: "none" } }}>
              <Box sx={{ display: "flex", justifyContent: "center", }}>
                <Box
                  sx={{
                    mt: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins-SemiBold",
                      fontSize: "24px",
                      lineHeight: "35px",
                      textAlign: "center",
                      color: "#1C212D",
                      paddingLeft: { lg: "230px", xs: "0px" }
                    }}
                  >
                    {t("authorizationpanel.rightContent.title")}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ textAlign: "center", mt: -1 }}>
                <img src={underLine} alt="under line" />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} lg={12} sx={{ display: { xs: "none", lg: "block" } }}>
            <Box sx={{ flexDirection: "column", display: "flex", justifyContent: "center", }}>
              <Box
                sx={{
                  mt: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "24px",
                    lineHeight: "35px",
                    textAlign: "center",
                    color: "#1C212D",
                  }}
                >
                  {t("authorizationpanel.rightContent.title")}
                </Typography>

                <Box sx={{ textAlign: "center", mt: -1 }}>
                  <img src={underLine} alt="under line" />
                </Box>
              </Box>
            </Box>
          </Grid>
          {(cookies.role == 'admin' || cookies.role == 'manager') && (cookies.isUserPanelsVisible === "true") && (
            <Grid item>
            <Box sx={{
              ml: 1.4,
              mb: '10px',
              borderRadius: '10px',
            }}>
              <AuthPanelFilter
                setSearchQuery={setSearchQuery}
                subUserEmail={subUserEmail}
                setSubUserEmail={setSubUserEmail}
                isUpdateClick = {isUpdateClick}
                setCurrentPage={setCurrentPage}
                setShowMore={setShowMore}
              />
            </Box>
          </Grid>
          )}
          <Grid item sx={{ zIndex: 3 }}>
            <Box sx={{
              ml: 1.4,
              border: '1px solid #FF8D28',
              borderRadius: '10px',
            }}>
              <AuthorizationPanelSearchBar
                setSearchQuery={setSearchQuery}
                fetchPanelList={fetchPanelList}
                setCurrentPage={setCurrentPage}
                setShowMore={setShowMore}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box sx={{ clear: "both", width: "100%", mt: "25px" }}>
          <AuthorizationPanelTable
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            data={initialPanelList}
            gData={initialGroupPanelList}
            panelList={panelList}
            groupPanelList={groupPanelList}
            groupPanelList1={groupPanelList1}
            panelList1={panelList1}
            setPanelList={setPanelList}
            setpanelList1={setpanelList1}
            setGroupPanelList={setGroupPanelList}
            setGroupPanelList1={setGroupPanelList1}
            subUserEmail={subUserEmail}
            setSubUserEmail={setSubUserEmail}
          />
          <Box
            sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 0 }}
          >
            <Button
              disabled={hasNoMoreItems}
              onClick={seeMoreHandler}
              sx={{
                color: "#FF8D28",
                textDecoration: "underline",
                fontSize: 18,
                fontFamily: "Gilroy-Medium",
                textTransform: "capitalize",
              }}
              variant="text"
            >
              {t("see.seemore")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default AuthorizationPanel;
