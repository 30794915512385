import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate, Link } from "react-router-dom";
import logoImage from "../../../assets/images/logo-white.svg";
import logoShape from "../../../assets/images/logo-shape.svg";
import leftBorder from "../../../assets/images/border-left-menu.svg";
import leftBorderTwo from "../../../assets/images/border-left-menu copy.svg";
import leftBorderEnd from "../../../assets/images/border-left-menu-end.svg";
import consultGray from "../../../assets/images/IF_Blanc.svg";
import consultBlue from "../../../assets/images/IF_Blue.svg";
import manageGray from "../../../assets/images/manage-white.svg";
import featureGray from "../../../assets/images/feature-gray.png";
import couponGray from "../../../assets/images/coupon-white.svg";
import couponBlue from "../../../assets/images/coupon-blue.png";
import profileGray from "../../../assets/images/profile-white.svg";
import profileBlue from "../../../assets/images/profile-blue.png";
import apiGray from "../../../assets/images/api-white.svg";
import apiBlue from "../../../assets/images/api-blue.png";
import aidGray from "../../../assets/images/aid-white.svg";
import aidBlue from "../../../assets/images/aid-blue.png";
import uploadBlue from "../../../assets/images/bulk-upload-blue.png";
import uploadWhite from "../../../assets/images/bulk-upload-white.png";
import panelWhite from "../../../assets/images/panel-white.png";
import panelBlue from "../../../assets/images/panel-blue.png";
import FABlue from "../../../assets/images/factor-blue.svg";
import FAGray from "../../../assets/images/factor-gray.svg";
import referencesGray from "../../../assets/images/references-white.svg";
import referencesBlue from "../../../assets/images/references-blue.svg";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Header from "../Header/Header";
import LogoutModal from "../Modal/LogoutModal";
import GroupIcon from "@mui/icons-material/Group";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Groups3Icon from "@mui/icons-material/Groups3";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { getCookiesList } from "../../../utils/cookiesHelper";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context";
import MobileDrawer from "./MobileDrawer";
import emoduleWhite from "../../../assets/images/company-enterprise-icon_blanc.svg"
import emoduleBlue from "../../../assets/images/company-enterprise-icon_blue.svg";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0px",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 33,
  marginBottom: 30,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `100%`,
  [theme.breakpoints.up("md")]: {
    width: `calc(100% - 70px)`,
  },
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `100%`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:1140px)");
  const [showMenuLogout, setShowMenuLogout] = useState(false);
  const [cookieList, setCookieList] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    open,
    setOpen,
    mobileView,
    handleDrawerToggle,
    openCollapse,
    setOpenCollapse,
    openCollapseTwo,
    setOpenCollapseTwo,
  } = useGlobalContext();

  useEffect(() => {
    const userCookiesList = getCookiesList("userInfo");
    if (userCookiesList.length > 0) {
      setCookieList(userCookiesList);
    }
  }, []);
  // React.useEffect(() => {
  //   matches ? setOpen(true) : setOpen(false);
  // }, [matches]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    // alert(open)
    setOpen(!open);
  };

  const handleClick = () => {
    if (openCollapseTwo) {
      setOpenCollapseTwo(false);
    }
    setOpenCollapse(!openCollapse);
  };

  const handleClickCollapse = () => {
    if (openCollapse) {
      setOpenCollapse(false);
    }
    setOpenCollapseTwo(!openCollapseTwo);
  };

  const dashboardHandler = () => {
    navigate("/dashboard");
    handleDrawerToggle();
  };
  const administratorUsersHandler = () => {
    if (cookieList && cookieList[12] == "manager" || cookieList[12] == "admin" ){
        navigate("/AdministratorUsers");
        handleDrawerToggle();
      }
  };

  const couponsManagerHandler = () => {
    navigate("/CouponsManager");
    handleDrawerToggle();
  };

  const referencesHandler = () => {
    navigate("/LaborReferences");
    handleDrawerToggle();
  };

  const couponsHandler = () => {
    navigate("/Coupons");
    handleDrawerToggle();
  };

  const employmentReferencesHandler = () => {
    navigate("/EmploymentReferences");
    handleDrawerToggle();
  };

  const bulkUploadHandler = () => {
    navigate("/BulkUpload");
    handleDrawerToggle();
  };
  const eModuleHandler = () => {
    navigate("/Emodule");
    handleDrawerToggle();
  };

  const panelHandler = () => {
    navigate("/authorizationpanel");
    handleDrawerToggle();
  };

  const customizedReportHandler = () => {
    if (cookieList && cookieList[12] == "manager" || cookieList[12] == "admin" ){
      navigate("/CustomizedReport");
      handleDrawerToggle();
    }
  };

  const informationHandler = () => {
    navigate("/CompanyInformation");

    handleDrawerToggle();
  };

  const apiHandler = () => {
    navigate("/api");

    handleDrawerToggle();
  };

  const aidHandler = () => {
    navigate("/aid");

    handleDrawerToggle();
  };

  const twoFAConfigurationHandler = () => {
    navigate("/2FAConfiguration");
    handleDrawerToggle();
  };

  const showCompanyInfo = () => {
    return !(cookieList[13] === "FARMACIAS DEL AHORRO" && cookieList[12] === "usuario")
  }

  return (
    <>
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          right: "auto",
          [theme.breakpoints.up("sm")]: { right: "0" },
        }}
        position="fixed"
        open={open}
      >
        <Box sx={{ m: 2, backgroundColor: "#ffffff", boxShadow: 4 }}>
          <Header />
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", left: 0, top: 0, zIndex: 1204 }}>
            <IconButton
              sx={{
                backgroundColor: "#fff",
                color: "#FF8D28",
                borderRadius: 50,
                boxShadow: 4,
                p: 0,
              }}
              onClick={
                mobileView
                  ? () => handleDrawerToggle()
                  : () => handleDrawerClose()
              }
            >
              {!open === false ? (
                <ChevronLeftIcon sx={{ fontSize: 40 }} />
              ) : (
                <ChevronRightIcon sx={{ fontSize: 40 }} />
              )}
            </IconButton>
          </Box>
        </Box>
      </AppBar>
      {mobileView ? (
        <MobileDrawer
          cookieList={cookieList}
          dashboardHandler={dashboardHandler}
          bulkUploadHandler={bulkUploadHandler}
          eModuleHandler={eModuleHandler}
          apiHandler={apiHandler}
          couponsHandler={couponsHandler}
          panelHandler={panelHandler}
          employmentReferencesHandler={employmentReferencesHandler}
          handleClickCollapse={handleClickCollapse}
          informationHandler={informationHandler}
          twoFAConfigurationHandler={twoFAConfigurationHandler}
          customizedReportHandler={customizedReportHandler}
          handleClick={handleClick}
          administratorUsersHandler={administratorUsersHandler}
          couponsManagerHandler={couponsManagerHandler}
          referencesHandler={referencesHandler}
          aidHandler={aidHandler}
          showMenuLogout={showMenuLogout}
        />
      ) : (
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#FF8D28",
              borderTopRightRadius: 20,
              borderBottomRightRadius: 20,
            },
          }}
          variant="permanent"
          open={open}
        >
          <DrawerHeader>
            {!open === false ? (
              <Box sx={{ height: "55px", textAlign: "left" }}>
                <Link to="/dashboard">
                  {" "}
                  <img src={logoImage} height="54px" />{" "}
                </Link>
              </Box>
            ) : (
              <Box sx={{ height: "48px", textAlign: "left" }}>
                <Link to="/dashboard">
                  {" "}
                  <img src={logoShape} height="48px" />{" "}
                </Link>
              </Box>
            )}
          </DrawerHeader>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Divider
              sx={{
                width: !open === false ? "70%" : "50px",
                borderStyle: "dashed",
              }}
            />
          </Box>
          <Box
            sx={{
              maxHeight: "66vh",
              overflowX: "inherit",
              overflowY: "auto",
            }}
          >
            <List>
              {
                ((cookieList[19] === "E_Module") || (cookieList[18] === "E_Module")) ? (

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {location.pathname === "/Emodule" ? (
                      <>
                        {!open === false && (
                          <Box
                            sx={{
                              width: 5,
                              backgroundColor: "#F2F8FF",
                              height: 50,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          ></Box>
                        )}
                      </>
                    ) : (
                      <>
                        {!open === false ? (
                          <Box
                            sx={{
                              width: 5,
                              backgroundColor: "transparent",
                              height: 50,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          ></Box>
                        ) : null}
                      </>
                    )}
                    <ListItem
                      disablePadding
                      sx={
                        location.pathname === "/Emodule"
                          ? {
                            width: open ? "90%" : matches ? 56 : 48,
                            mx: "auto",
                          }
                          : {
                            width: open ? "90%" : matches ? 56 : 54,
                            mx: "auto",
                            "& .MuiListItemIcon-root": {
                              color: "primary.main",
                            },
                          }
                      }
                    >
                      <ListItemButton
                        onClick={eModuleHandler}
                        sx={
                          location.pathname === "/Emodule"
                            ? {
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: "16px",
                              width: "100%",
                              backgroundColor: "#F2F8FF",
                              borderRadius: 1,
                            }
                            : {
                              width: "100%",
                            }
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "24px",
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {location.pathname === "/Emodule" ? (
                            <img src={emoduleBlue} alt="Emodule Upload" />
                          ) : (
                            <img src={emoduleWhite} alt="Emodule Upload" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={t("Dashboard.Companies")}
                          sx={{
                            opacity: open ? 1 : 0,
                            fontFamily: "Gilroy-Medium",
                            "& .MuiListItemText-primary": {
                              fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                              color:
                                location.pathname === "/Emodule"
                                  ? "#000000"
                                  : "white",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Box>

                ) :
                  ((cookieList[19] === "Recruiter") || (cookieList[18] === "Recruiter")) ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      {location.pathname === "/dashboard" ? (
                        <>
                          {!open === false ? (
                            <Box
                              sx={{
                                width: 5,
                                backgroundColor: "#F2F8FF",
                                height: 50,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                              }}
                            ></Box>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {!open === false ? (
                            <Box
                              sx={{
                                width: 5,
                                backgroundColor: "transparent",
                                height: 50,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                              }}
                            ></Box>
                          ) : null}
                        </>
                      )}
                      <ListItem
                        disablePadding
                        sx={
                          location.pathname === "/dashboard"
                            ? {
                              width: open ? "90%" : matches ? 56 : 48,
                              mx: "auto",
                            }
                            : {
                              width: open ? "90%" : matches ? 56 : 54,
                              mx: "auto",
                              "& .MuiListItemIcon-root": {
                                color: "primary.main",
                              },
                            }
                        }
                      >
                        <ListItemButton
                          onClick={dashboardHandler}
                          sx={
                            location.pathname === "/dashboard"
                              ? {
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: "16px",
                                width: "100%",
                                backgroundColor: "#F2F8FF",
                                borderRadius: 1,
                              }
                              : {
                                width: "100%",
                              }
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "24px",
                              mr: open ? 2 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {location.pathname === "/dashboard" ? (
                              <img src={consultBlue} alt="Consult" />
                            ) : (
                              <img src={consultGray} alt="Consult" />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={t("Dashboard.Candidates")}
                            sx={{
                              opacity: open ? 1 : 0,
                              fontFamily: "Gilroy-Medium",
                              "& .MuiListItemText-primary": {
                                fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                                color:
                                  location.pathname === "/dashboard"
                                    ? "#000000"
                                    : "white",
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Box>

                  ) :
                    (
                      <>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {location.pathname === "/dashboard" ? (
                            <>
                              {!open === false ? (
                                <Box
                                  sx={{
                                    width: 5,
                                    backgroundColor: "#F2F8FF",
                                    height: 50,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                  }}
                                ></Box>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {!open === false ? (
                                <Box
                                  sx={{
                                    width: 5,
                                    backgroundColor: "transparent",
                                    height: 50,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                  }}
                                ></Box>
                              ) : null}
                            </>
                          )}
                          <ListItem
                            disablePadding
                            sx={
                              location.pathname === "/dashboard"
                                ? {
                                  width: open ? "90%" : matches ? 56 : 48,
                                  mx: "auto",
                                }
                                : {
                                  width: open ? "90%" : matches ? 56 : 54,
                                  mx: "auto",
                                  "& .MuiListItemIcon-root": {
                                    color: "primary.main",
                                  },
                                }
                            }
                          >
                            <ListItemButton
                              onClick={dashboardHandler}
                              sx={
                                location.pathname === "/dashboard"
                                  ? {
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: "16px",
                                    width: "100%",
                                    backgroundColor: "#F2F8FF",
                                    borderRadius: 1,
                                  }
                                  : {
                                    width: "100%",
                                  }
                              }
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: "24px",
                                  mr: open ? 2 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {location.pathname === "/dashboard" ? (
                                  <img src={consultBlue} alt="Consult" />
                                ) : (
                                  <img src={consultGray} alt="Consult" />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={t("Dashboard.Candidates")}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  fontFamily: "Gilroy-Medium",
                                  "& .MuiListItemText-primary": {
                                    fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                                    color:
                                      location.pathname === "/dashboard"
                                        ? "#000000"
                                        : "white",
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {location.pathname === "/Emodule" ? (
                            <>
                              {!open === false && (
                                <Box
                                  sx={{
                                    width: 5,
                                    backgroundColor: "#F2F8FF",
                                    height: 50,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                  }}
                                ></Box>
                              )}
                            </>
                          ) : (
                            <>
                              {!open === false ? (
                                <Box
                                  sx={{
                                    width: 5,
                                    backgroundColor: "transparent",
                                    height: 50,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                  }}
                                ></Box>
                              ) : null}
                            </>
                          )}
                          <ListItem
                            disablePadding
                            sx={
                              location.pathname === "/Emodule"
                                ? {
                                  width: open ? "90%" : matches ? 56 : 48,
                                  mx: "auto",
                                }
                                : {
                                  width: open ? "90%" : matches ? 56 : 54,
                                  mx: "auto",
                                  "& .MuiListItemIcon-root": {
                                    color: "primary.main",
                                  },
                                }
                            }
                          >
                            <ListItemButton
                              onClick={eModuleHandler}
                              sx={
                                location.pathname === "/Emodule"
                                  ? {
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: "16px",
                                    width: "100%",
                                    backgroundColor: "#F2F8FF",
                                    borderRadius: 1,
                                  }
                                  : {
                                    width: "100%",
                                  }
                              }
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: "24px",
                                  mr: open ? 2 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {location.pathname === "/Emodule" ? (
                                  <img src={emoduleBlue} alt="emodule Upload" />
                                ) : (
                                  <img src={emoduleWhite} alt="emodule Upload" />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={t("Dashboard.Companies")}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  fontFamily: "Gilroy-Medium",
                                  "& .MuiListItemText-primary": {
                                    fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                                    color:
                                      location.pathname === "/Emodule"
                                        ? "#000000"
                                        : "white",
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </Box>
                      </>
                    )
              }

              {
                ((cookieList[19] === "E_Module" || cookieList[18] === "E_Module") === false) && (
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {location.pathname === "/BulkUpload" ? (
                      <>
                        {!open === false && (
                          <Box
                            sx={{
                              width: 5,
                              backgroundColor: "#F2F8FF",
                              height: 50,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          ></Box>
                        )}
                      </>
                    ) : (
                      <>
                        {!open === false ? (
                          <Box
                            sx={{
                              width: 5,
                              backgroundColor: "transparent",
                              height: 50,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          ></Box>
                        ) : null}
                      </>
                    )}
                    <ListItem
                      disablePadding
                      sx={
                        location.pathname === "/BulkUpload"
                          ? {
                            width: open ? "90%" : matches ? 56 : 48,
                            mx: "auto",
                          }
                          : {
                            width: open ? "90%" : matches ? 56 : 54,
                            mx: "auto",
                            "& .MuiListItemIcon-root": {
                              color: "primary.main",
                            },
                          }
                      }
                    >
                      <ListItemButton
                        onClick={bulkUploadHandler}
                        sx={
                          location.pathname === "/BulkUpload"
                            ? {
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: "16px",
                              width: "100%",
                              backgroundColor: "#F2F8FF",
                              borderRadius: 1,
                            }
                            : {
                              width: "100%",
                            }
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "24px",
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {location.pathname === "/BulkUpload" ? (
                            <img src={uploadBlue} alt="Bulk Upload" />
                          ) : (
                            <img src={uploadWhite} alt="Bulk Upload" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={t("Dashboard.Bulk Upload")}
                          sx={{
                            opacity: open ? 1 : 0,
                            fontFamily: "Gilroy-Medium",
                            "& .MuiListItemText-primary": {
                              fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                              color:
                                location.pathname === "/BulkUpload"
                                  ? "#000000"
                                  : "white",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                )
              }
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {location.pathname === "/authorizationpanel" ? (
                  <>
                    {!open === false && (
                      <Box
                        sx={{
                          width: 5,
                          backgroundColor: "#F2F8FF",
                          height: 50,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                      ></Box>
                    )}
                  </>
                ) : (
                  <>
                    {!open === false ? (
                      <Box
                        sx={{
                          width: 5,
                          backgroundColor: "transparent",
                          height: 50,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                      ></Box>
                    ) : null}
                  </>
                )}
                <ListItem
                  disablePadding
                  sx={
                    location.pathname === "/authorizationpanel"
                      ? {
                        width: open ? "90%" : matches ? 56 : 48,
                        mx: "auto",
                      }
                      : {
                        width: open ? "90%" : matches ? 56 : 54,
                        mx: "auto",
                        "& .MuiListItemIcon-root": {
                          color: "primary.main",
                        },
                      }
                  }
                >
                  <ListItemButton
                    onClick={panelHandler}
                    sx={
                      location.pathname === "/authorizationpanel"
                        ? {
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: "16px",
                          width: "100%",
                          backgroundColor: "#F2F8FF",
                          borderRadius: 1,
                        }
                        : {
                          width: "100%",
                        }
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "24px",
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {location.pathname === "/authorizationpanel" ? (
                        <img src={panelBlue} alt="Panel Upload" />
                      ) : (
                        <img src={panelWhite} alt="Panel Upload" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Panel")}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontFamily: "Gilroy-Medium",
                        "& .MuiListItemText-primary": {
                          fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                          color:
                            location.pathname === "/authorizationpanel"
                              ? "#000000"
                              : "white",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {location.pathname === "/Coupons" ? (
                  <>
                    {!open === false && (
                      <Box
                        sx={{
                          width: 5,
                          backgroundColor: "#F2F8FF",
                          height: 50,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                      ></Box>
                    )}
                  </>
                ) : (
                  <>
                    {!open === false ? (
                      <Box
                        sx={{
                          width: 5,
                          backgroundColor: "transparent",
                          height: 50,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                      ></Box>
                    ) : null}
                  </>
                )}
                <ListItem
                  disablePadding
                  sx={
                    location.pathname === "/Coupons"
                      ? {
                        width: open ? "90%" : matches ? 56 : 48,
                        mx: "auto",
                      }
                      : {
                        width: open ? "90%" : matches ? 56 : 54,
                        mx: "auto",
                        "& .MuiListItemIcon-root": {
                          color: "primary.main",
                        },
                      }
                  }
                >
                  <ListItemButton
                    onClick={couponsHandler}
                    sx={
                      location.pathname === "/Coupons"
                        ? {
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: "16px",
                          width: "100%",
                          backgroundColor: "#F2F8FF",
                          borderRadius: 1,
                        }
                        : {
                          width: "100%",
                        }
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "24px",
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {location.pathname === "/Coupons" ? (
                        <img src={couponBlue} alt="Coupons" />
                      ) : (
                        <img src={couponGray} alt="Coupons" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Dashboard.Coupons")}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontFamily: "Gilroy-Medium",
                        "& .MuiListItemText-primary": {
                          fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                          color:
                            location.pathname === "/Coupons"
                              ? "#000000"
                              : "white",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Box>

            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Divider
                sx={{
                  width: !open === false ? "70%" : "50px",
                  borderStyle: "dashed",
                  borderColor: "neutral.300",
                }}
              />
            </Box>
            <List>
              <ListItem
                disablePadding
                sx={{
                  width: open ? "90%" : matches ? 56 : 48,
                  mx: "auto",
                  // paddingLeft: "5px"
                }}
              >
                <ListItemButton onClick={handleClickCollapse}>
                  <ListItemIcon
                    sx={{
                      ml: open ? "2px" : "-1px",
                      minWidth: "24px",
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={profileGray} alt="Profile" />
                  </ListItemIcon>

                  <>
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        "& .MuiListItemText-primary": {
                          fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                          color: "white",
                        },
                      }}
                      primary={t("Dashboard.Profile")}
                    />
                    <Box sx={{ display: "block", opacity: open ? 1 : 0 }}>
                      {openCollapseTwo ? (
                        <ExpandLess sx={{ color: "white" }} />
                      ) : (
                        <ExpandMore sx={{ color: "white" }} />
                      )}
                    </Box>
                  </>
                </ListItemButton>
              </ListItem>
              <Collapse in={openCollapseTwo} timeout="auto" unmountOnExit>
                <Box
                  sx={{ position: "absolute", left: 30, opacity: open ? 1 : 0 }}
                >
                  <img src={ (cookieList[12] == 'manager' || cookieList[12] == 'admin') ? leftBorder:leftBorderEnd} height={120} style={{marginTop: (cookieList[12] == 'manager' || cookieList[12] == 'admin') ? "0":"-7vh", marginLeft: "10px"}} alt="left side" />
                </Box>
                <List disablePadding>
                  <ListItem
                    sx={{
                      p: 0,
                      width: open ? "90%" : matches ? 56 : 48,
                      mx: "auto",
                    }}
                  >
                    <ListItemButton
                      onClick={informationHandler}
                      sx={{
                        p: 0,
                        backgroundColor:
                          location.pathname === "/CompanyInformation"
                            ? "#F2F8FF"
                            : "transparent",
                        borderRadius: 1,
                        ml: open ? "48px" : "0px",
                        minHeight: 48,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          // display: { xs: "block", sm: "block", lg: "none" },
                          minWidth: "24px",
                          px: 1.6,
                          py: 0,
                          justifyContent: "center",
                          display: open ? "none" : "unset",
                        }}
                      >
                        {location.pathname === "/CompanyInformation" ? (
                          <CloudUploadIcon sx={{ color: "#4165F6" }} />
                        ) : (
                          <CloudUploadIcon sx={{ color: "#ffffff" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          fontFamily: "Gilroy-Medium",
                          // px: 2,
                          pl: "6px",
                          py: 1,
                          borderRadius: 1,
                          "& .MuiListItemText-primary": {
                            fontSize: {
                              sx: 12,
                              sm: 12,
                              md: 12,
                              lg: 14,
                              xl: 16,
                            },
                            color:
                              location.pathname === "/CompanyInformation"
                                ? "#000000"
                                : "white",
                          },
                        }}
                        primary={t("Dashboard.Company Information")}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{
                      p: 0,
                      width: open ? "90%" : matches ? 56 : 48,
                      mx: "auto",
                    }}
                  >
                    <ListItemButton
                      onClick={twoFAConfigurationHandler}
                      sx={{
                        p: 0,
                        backgroundColor:
                          location.pathname === "/2FAConfiguration"
                            ? "#F2F8FF"
                            : "transparent",
                        borderRadius: 1,
                        ml: open ? "48px" : "0px",
                        minHeight: 48,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "24px",
                          px: 1.6,
                          py: 0,
                          justifyContent: "center",
                          display: open ? "none" : "unset",
                        }}
                      >
                        {location.pathname === "/2FAConfiguration" ? (
                          <CloudUploadIcon sx={{ color: "#4165F6" }} />
                        ) : (
                          <CloudUploadIcon sx={{ color: "white" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          fontFamily: "Gilroy-Medium",
                          pl: "6px",
                          py: 1,

                          "& .MuiListItemText-primary": {
                            fontSize: {
                              sx: 12,
                              sm: 12,
                              md: 12,
                              lg: 14,
                              xl: 16,
                            },
                            color:
                              location.pathname === "/2FAConfiguration"
                                ? "#000000"
                                : "white",
                          },
                        }}
                        primary={t("Dashboard.2FA Configuration")}
                      />
                    </ListItemButton>
                  </ListItem>
                  {
                    showCompanyInfo() && ( cookieList[12] == "manager" || cookieList[12] == "admin" ) ?(<ListItem
                      sx={{
                        p: 0,
                        width: open ? "90%" : matches ? 56 : 48,
                        mx: "auto",
                      }}
                    >
                      <ListItemButton
                        onClick={customizedReportHandler}
                        sx={{
                          p: 0,
                          backgroundColor:
                            location.pathname === "/CustomizedReport"
                              ? "#F2F8FF"
                              : "transparent",
                          borderRadius: 1,
                          ml: open ? "48px" : "0px",
                          minHeight: 48,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "24px",
                            px: 1.6,
                            py: 0,
                            justifyContent: "center",
                            display: open ? "none" : "unset",
                          }}
                        >
                          {location.pathname === "/CustomizedReport" ? (
                            <SummarizeIcon sx={{ color: "#4165F6" }} />
                          ) : (
                            <SummarizeIcon sx={{ color: "white" }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            fontFamily: "Gilroy-Medium",
                            py: 1,
                            pl: "6px",
                            "& .MuiListItemText-primary": {
                              fontSize: {
                                sx: 12,
                                sm: 12,
                                md: 12,
                                lg: 14,
                                xl: 16,
                              },
                              color:
                                location.pathname === "/CustomizedReport"
                                  ? "#000000"
                                  : "white",
                            },
                          }}
                          primary={t("Dashboard.Customized Report")}
                        />
                      </ListItemButton>
                    </ListItem>) : null
                  }
                </List>
              </Collapse>
              <ListItem
                disablePadding
                sx={{
                  width: open ? "90%" : matches ? 56 : 48,
                  mx: "auto",
                  // paddingLeft: "3px"
                }}
              >
                {cookieList[12] == "manager" || cookieList[12] == "admin" ? (
                  <ListItemButton onClick={handleClick}>
                    <ListItemIcon
                      sx={{
                        minWidth: "24px",
                        ml: open ? "2px" : "-1px",
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img src={manageGray} alt="Manage" />
                    </ListItemIcon>

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        "& .MuiListItemText-primary": {
                          fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                          color: "white",
                        },
                      }}
                      primary={t("Dashboard.Manage")}
                    />
                    <Box sx={{ display: "block", opacity: open ? 1 : 0 }}>
                      {openCollapse ? (
                        <ExpandLess sx={{ color: "white" }} />
                      ) : (
                        <ExpandMore sx={{ color: "white" }} />
                      )}
                    </Box>
                  </ListItemButton>
                ) : null}
              </ListItem>
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <Box
                  sx={{ position: "absolute", left: 30, opacity: open ? 1 : 0 }}
                >
                  <img src={leftBorderEnd} height={120} style={{ marginTop: "-5vh", marginLeft: "10px" }} alt="left side" />
                </Box>
                <List disablePadding>
                  { cookieList[12] == "manager" || cookieList[12] == "admin" ? (
                    <ListItem
                    sx={{
                      p: 0,
                      width: open ? "90%" : matches ? 56 : 48,
                      mx: "auto",
                    }}
                  >
                    <ListItemButton
                      onClick={administratorUsersHandler}
                      sx={{
                        p: 0,
                        backgroundColor:
                          location.pathname === "/AdministratorUsers"
                            ? "#F2F8FF"
                            : "transparent",
                        borderRadius: 1,
                        ml: open ? "48px" : "0px",
                        minHeight: 48,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "24px",
                          px: 1.6,
                          py: 0,
                          justifyContent: "center",
                          display: open ? "none" : "unset",
                        }}
                      >
                        {location.pathname === "/AdministratorUsers" ? (
                          <GroupIcon sx={{ color: "#4165F6" }} />
                        ) : (
                          <GroupIcon sx={{ color: "white" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          fontFamily: "Gilroy-Medium",
                          pl: "6px",
                          py: 1,
                          "& .MuiListItemText-primary": {
                            fontSize: {
                              sx: 12,
                              sm: 12,
                              md: 12,
                              lg: 14,
                              xl: 16,
                            },
                            color:
                              location.pathname === "/AdministratorUsers"
                                ? "#000000"
                                : "white",
                          },
                        }}
                        primary={t("Dashboard.Administrator Users")}
                      />
                    </ListItemButton>
                  </ListItem>
                  ) : null }
                  <ListItem
                    sx={{
                      p: 0,
                      width: open ? "90%" : matches ? 56 : 48,
                      mx: "auto",
                    }}
                  >
                    <ListItemButton
                      onClick={couponsManagerHandler}
                      sx={{
                        p: 0,
                        backgroundColor:
                          location.pathname === "/CouponsManager"
                            ? "#F2F8FF"
                            : "transparent",
                        borderRadius: 1,
                        ml: open ? "48px" : "0px",
                        minHeight: 48,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "24px",
                          px: 1.6,
                          py: 0,
                          justifyContent: "center",
                          display: open ? "none" : "unset",
                        }}
                      >
                        {location.pathname === "/CouponsManager" ? (
                          <LocalOfferIcon sx={{ color: "#4165F6" }} />
                        ) : (
                          <LocalOfferIcon sx={{ color: "white" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          fontFamily: "Gilroy-Medium",
                          // ml: open ? {xs: 0, sm: 0, lg: 1} : 1,
                          pl: "6px",
                          py: 1,
                          "& .MuiListItemText-primary": {
                            fontSize: {
                              sx: 12,
                              sm: 12,
                              md: 12,
                              lg: 14,
                              xl: 16,
                            },
                            color:
                              location.pathname === "/CouponsManager"
                                ? "#000000"
                                : "white",
                          },
                        }}
                        primary={t("Dashboard.Coupons Manager")}
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem
                    sx={{
                      p: 0,
                      width: open ? "90%" : matches ? 56 : 48,
                      mx: "auto",
                    }}
                  >
                    <ListItemButton
                      onClick={referencesHandler}
                      sx={{
                        p: 0,
                        backgroundColor:
                          location.pathname === "/LaborReferences"
                            ? "#F2F8FF"
                            : "transparent",
                        borderRadius: 1,
                        ml: open ? "48px" : "0px",
                        minHeight: 48,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "24px",
                          px: 1.6,
                          py: 0,
                          justifyContent: "center",
                          display: open ? "none" : "unset",
                        }}
                      >
                        {location.pathname === "/LaborReferences" ? (
                          <Groups3Icon sx={{ color: "#4165F6" }} />
                        ) : (
                          <Groups3Icon sx={{ color: "WHITE" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          fontFamily: "Gilroy-Medium",
                          pl: "6px",
                          py: 1,

                          "& .MuiListItemText-primary": {
                            fontSize: {
                              sx: 12,
                              sm: 12,
                              md: 12,
                              lg: 14,
                              xl: 16,
                            },
                            color:
                              location.pathname === "/LaborReferences"
                                ? "#000000"
                                : "white",
                          },
                        }}
                        primary={t("Dashboard.Labor Reference")}
                      />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
              {

                ((cookieList[19] === "E_Module" || cookieList[18] === "E_Module") === false) && (
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {location.pathname === "/api" ? (
                      <>
                        {!open === false && (
                          <Box
                            sx={{
                              width: 5,
                              backgroundColor: "#F2F8FF",
                              height: 50,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          ></Box>
                        )}
                      </>
                    ) : (
                      <>
                        {!open === false ? (
                          <Box
                            sx={{
                              width: 5,
                              backgroundColor: "transparent",
                              height: 50,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          ></Box>
                        ) : null}
                      </>
                    )}
                    <ListItem
                      disablePadding
                      sx={
                        location.pathname === "/api"
                          ? {
                            width: open ? "90%" : matches ? 56 : 48,
                            mx: "auto",
                          }
                          : {
                            width: open ? "90%" : matches ? 56 : 54,
                            mx: "auto",
                            "& .MuiListItemIcon-root": {
                              color: "primary.main",
                            },
                          }
                      }
                    >
                      <ListItemButton
                        onClick={apiHandler}
                        sx={
                          location.pathname === "/api"
                            ? {
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: "16px",
                              width: "100%",
                              backgroundColor: "#F2F8FF",
                              borderRadius: 1,
                            }
                            : {
                              width: "100%",
                            }
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "24px",
                            mr: open ? 2 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {location.pathname === "/api" ? (
                            <img src={apiBlue} alt="API" />
                          ) : (
                            <img src={apiGray} alt="API" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary="API"
                          sx={{
                            opacity: open ? 1 : 0,
                            fontFamily: "Gilroy-Medium",
                            "& .MuiListItemText-primary": {
                              fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                              color:
                                location.pathname === "/api" ? "#000000" : "white",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Box>
                )
              }

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {location.pathname === "/aid" ? (
                  <>
                    {!open === false && (
                      <Box
                        sx={{
                          width: 5,
                          backgroundColor: "#F2F8FF",
                          height: 50,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                      ></Box>
                    )}
                  </>
                ) : (
                  <>
                    {!open === false ? (
                      <Box
                        sx={{
                          width: 5,
                          backgroundColor: "transparent",
                          height: 50,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                        }}
                      ></Box>
                    ) : null}
                  </>
                )}
                <ListItem
                  disablePadding
                  sx={
                    location.pathname === "/aid"
                      ? {
                        width: open ? "90%" : matches ? 56 : 48,
                        mx: "auto",
                      }
                      : {
                        width: open ? "90%" : matches ? 56 : 54,
                        mx: "auto",

                        "& .MuiListItemIcon-root": {
                          color: "primary.main",
                        },
                      }
                  }
                >
                  <ListItemButton
                    onClick={aidHandler}
                    sx={
                      location.pathname === "/aid"
                        ? {
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: "16px",
                          width: "100%",
                          backgroundColor: "#F2F8FF",
                          borderRadius: 1,
                        }
                        : {
                          width: "100%",
                        }
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "24px",
                        ml: "-0px",
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {location.pathname === "/aid" ? (
                        <img src={aidBlue} alt="aid" />
                      ) : (
                        <img src={aidGray} alt="aid" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Dashboard.Aid")}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontFamily: "Gilroy-Medium",
                        "& .MuiListItemText-primary": {
                          fontSize: { sx: 12, sm: 12, md: 12, lg: 14, xl: 16 },
                          color:
                            location.pathname === "/aid" ? "#000000" : "white",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Box>
            </List>
          </Box>
          <Box
            sx={{
              position: showMenuLogout === true ? "unset" : "absolute",
              bottom: 6,
              zIndex: 99,
              left: 0,
              right: 0,
            }}
          >
            <Stack
              flexDirection="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                p: { xs: "8px", sm: "8px", lg: "10px" },
              }}
              spacing={2}
            >
              {!open === false ? (
                <Box
                  sx={{
                    width: "70%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 18,
                      fontFamily: "Poppins-SemiBold",
                      color: "white",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "170px",
                    }}
                  >
                    {cookieList[11] !== null
                      ? cookieList[11]
                      : "No name available"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 10,
                      fontFamily: "Poppins-Medium",
                      color: "neutral.500",
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    {cookieList[3] !== null
                      ? cookieList[3]
                      : "No email available"}
                  </Typography>
                </Box>
              ) : null}

              <Box
                sx={{ width: "30%", textAlign: "right", mt: "0px !important" }}
              >
                {/************************ Modal Logout ******************************/}

                <LogoutModal />
              </Box>
            </Stack>
          </Box>
        </Drawer>
      )}
    </>
  );
}
