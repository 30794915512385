import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, TableContainer } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import { useTranslation } from 'react-i18next';
import { sectionAData, sectionBData, sectionCData, sectionDData, sectionEData, sectionFData, sectionGData } from '../../../utils/EReportConstants';
import AccordianEreportHeader from './AccordianEreportHeader';
import AccordianEreportTableMain from './AccordianEreportTableMain';

const AccordianEreportTable = (reportData, sub_dicts, usuarioCupon) => {
  const { t } = useTranslation();

  const sections = [
    {
      title: "A",
      data: sectionAData,
      headerTitle: t("Ereportpage.sections.main_titles.A"),
    },
    {
      title: "B",
      data: sectionBData,
      headerTitle: t("Ereportpage.sections.main_titles.B"),
    },
    {
      title: "C",
      data: sectionCData,
      headerTitle: t("Ereportpage.sections.main_titles.C"),
    },
    {
      title: "D",
      data: sectionDData,
      headerTitle: t("Ereportpage.sections.main_titles.D"),
    },
    // {
    //   title: "E",
    //   data: sectionEData,
    //   headerTitle: t("Ereportpage.sections.main_titles.E"),
    // },

    // Conditionally include section E only if "tipo_cupon" is "CB"
    ...(reportData?.usuarioCupon === "CB"
      ? [
        {
          title: "E",
          data: sectionEData,
          headerTitle: t("reportpage.sections.main_titles.E"),
        },
      ]
      : []),
    {
      title: "F",
      data: sectionFData,
      headerTitle: t("Ereportpage.sections.main_titles.F"),
    },
    {
      title: "G",
      data: sectionGData,
      headerTitle: t("Ereportpage.sections.main_titles.G"),
    }

  ]
  const [expanded, setExpanded] = React.useState(
    sections.map((section) => section.title)
  ); // Set the first section as expanded


  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((item) => item !== panel));
    }
  };
  return (
    <>
      {sections.map((section) => {
        return (
          <Accordion
            key={section.title}
            expanded={expanded.includes(section.title)}
            onChange={handleChange(section.title)}
            sx={{
              mb: 2,
              "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: { xs: "20px !important", md: "45px !important" },
                maxHeight: { xs: "25px !important", sm: "45px !important", md: "45px !important" }
              },
              "& .MuiButtonBase-root.MuiAccordionSummary-root": {
                p: { xs: "0px 5px !important", md: "0px 16px !important" },
              },
            }}
          >
            <TableContainer
              sx={{
                backgroundColor: "primary.main",
                m: 0,
                borderStartEndRadius: "4px",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: 0,
              }}
              component={Paper}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "primary.main",
                      backgroundColor: "#ffffff",
                      borderRadius: 50,
                      fontSize: { xs: "small", md: "1.5rem" },
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  my: "0px !important",
                  "& .MuiAccordionSummary-content": {
                    m: "0px !important",
                    minHeight: "45px !important",
                  },
                }}
              >
                <AccordianEreportHeader
                  tableRowTitle={section.title}
                  tableRowheaderTitle={section.headerTitle}
                  reportData={reportData}
                />
              </AccordionSummary>
            </TableContainer>
            <AccordionDetails
              sx={{ display: "flex", flexDirection: "row", p: 0 }}
            >
              <AccordianEreportTableMain
                data={reportData}
                sectionAData={section.data}
                sub_dicts={sub_dicts}
              />
            </AccordionDetails>
          </Accordion>
        )
      })
      }
    </>
  )
}

export default AccordianEreportTable