import Cookies from 'js-cookie';


export const getCookiesList = (cookieKey) => {
    const userInfoCookie = Cookies.get(cookieKey)
    if (userInfoCookie != null && typeof(userInfoCookie) != 'object') {
        return userInfoCookie.split(",")
    }
    return [];
};

export const getCookiesDict = () => {
    const userInfoCookie = Cookies.get('userInfo')
    if (userInfoCookie != null && typeof(userInfoCookie) != 'object') {
        const infoList = userInfoCookie.split(",")
        return {
            name: infoList[0],
            lname: infoList[1],
            slname: infoList[2],
            email: infoList[3],
            phone: infoList[5],
            role: infoList[12],
            empresa: infoList[13],
            logo: infoList[14],
            address: infoList[15],
            userEmail: infoList[16],
            userPhone: infoList[17],
            isCustomizationEnabled: infoList[19],
            isUserPanelsVisible: infoList[20],
        }
    }
    return {};
};

export const removeCookie = (cookieValue) => {
    Cookies.remove(cookieValue)
};