import React, { useState } from 'react'
import './PasswordShowHideIcon.css'
import passIcon from "../../../../assets/images/LockIcon.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function PasswordShowHideIcon(props) {
    const [showPassword, setShowPassword] = useState(false);

    const { label, type = "text", inputValue, placeholder, name = "name", onChange, errorMsg = 'errorMsg', flag = false, containerStyle, readOnly = false, maxLength, disabled, inputStyle, inputContainerStyle, id = "input", star, error, mandatory } = props

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="li_container" style={containerStyle}>
            {
                label ? <><label htmlFor="" className="li_label">{label}<span style={{ color: "red" }}>{star}</span></label></> : null
            }
            <div className="li_input_container" style={inputContainerStyle}>
                <div className={mandatory ? "container_box mandatoryColor" : 'container_box'}>
                    <div className='icon_box'><img src={passIcon} alt="Lock icon" width={17} height={20} /></div>
                    <input id={id} value={inputValue} className="li_input" style={{
                        // Add conditional styling for the background color based on the error prop
                        backgroundColor: error ? '#feeadd' : 'transparent',
                    }} type={showPassword ? 'text' : 'password'} placeholder={placeholder} name={name} onChange={onChange} readOnly={readOnly} maxLength={maxLength} disabled={disabled} />
                    <span className="icon_box_right" onClick={handleTogglePasswordVisibility}>{showPassword ? <VisibilityOffIcon sx={{ color: "rgba(27, 32, 44, 0.4)" }} /> : <VisibilityIcon sx={{ color: "rgba(27, 32, 44, 0.4)" }} />}</span>
                </div>
                <p className="li_error_text" style={{ display: flag ? 'block' : 'none' }}>{errorMsg}</p>
            </div>
        </div>
    )
}

export default PasswordShowHideIcon
