import React from "react";
import "./LabelInputLeftIcon.css";
import mailImage from "../../../assets/images/mailIcon.png";
import { Checkbox, IconButton, MenuItem, Select, styled } from "@mui/material";
import { options_phone } from "../../../utils/optionsEdo";

const CustomSelect = styled(Select)({
  '&& .MuiSelect-select': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'clip',  // Use 'clip' to avoid ellipsis
    display: 'flex',
    alignItems: 'center',
  }
});

function LabelInputLeftIcon(props) {
  const {
    isPhone,
    prefix,
    setPrefix,
    mailIcon = { mailImage },
    label,
    type = "text",
    inputValue,
    placeholder,
    name = "name",
    onChange,
    errorMsg = "errorMsg",
    flag = false,
    containerStyle,
    readOnly = false,
    maxLength,
    disabled,
    inputStyle,
    inputContainerStyle,
    id = "input",
    star,
    error,
    moveRight,
    mandatory
  } = props;
  return (
    <div className="li_container" style={containerStyle}>
      {label ? (
        <>
          <label htmlFor="" className="li_label">
            {label}
            <span style={{ color: "red" }}>{star}</span>
          </label>
        </>
      ) : null}
      <div className="li_input_container" style={inputContainerStyle}>
        <div className={mandatory ? "container_box mandatoryColor" : 'container_box'}>
          <div className="icon_box">
            <img src={mailIcon} alt="mail icon" width={18} />
          </div>
          {moveRight &&
            <div className="" style={{ backgroundColor: "transparent", width: "90px" }}>

            </div>
          }
          {isPhone && (
            <>
              <CustomSelect
                id="country-select"
                value={prefix}
                label="Country"
                onChange={(e) => { setPrefix(e.target.value) }}
                MenuProps={{
                  sx: {
                    maxWidth: 300, // Limit the width to 300px or any other suitable value
                    maxHeight: 400, // Limit the height to 400px
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'  // Always transparent
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'  // Transparent on hover
                  }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',  // Override border color or make transparent
                    outline: 'none'  // Remove focus outline
                  },
                  width: '75px',
                }}
              >
                {options_phone.map(country => (
                  <MenuItem value={country.code} >
                    <img src={country.flag} alt={country.name} style={{ width: 24, marginRight: 10, verticalAlign: 'middle' }} />
                    <span style={{ fontSize: 15, color: "#1C212D", marginLeft: 27 }}>{`${country.name} (${country.code})`}</span>
                  </MenuItem>
                ))}
              </CustomSelect>
              <div style={{
                width: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: "solid 1px rgba(27, 32, 44, 0.2)",
                borderRight: "solid 1px rgba(27, 32, 44, 0.2)",
              }}>
                <span style={{ color: "#1C212D", fontSize: 15, margin: '0 10px' }}>{`(${prefix})`}</span>
              </div>
            </>
          )
          }
          <input
            id={id}
            value={inputValue}
            className="li_input"
            style={{
              // Add conditional styling for the background color based on the error prop
              backgroundColor: error ? "#feeadd" : "transparent",
            }}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={(e) => {
              if (type === "number") {
                // Ensure the entered value is not negative
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              }

              // Handle other input types or non-negative numbers
              if (maxLength && e.target.value.length > maxLength) {
                e.target.value = e.target.value.slice(0, maxLength);
              }

              onChange(e);
            }}
            readOnly={readOnly}
            maxLength={maxLength}
            disabled={disabled}
          />
        </div>
        <p
          className="li_error_text"
          style={{ display: flag ? "block" : "none" }}
        >
          {errorMsg}
        </p>
      </div>
    </div>
  );
}

export default LabelInputLeftIcon;
