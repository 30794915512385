import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { dataTypesConfig } from "../../../utils/reportDataConfigsAndFunctions";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
  },

  backgroundColor: "transparent",
  borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "solid 0px rgba(167, 169, 169, 0.16)",
}));

export default function InnerTable(props) {
  const { t } = useTranslation();
  const {
    tableData = [
      {
        Parentesco: "Children",
        Nombre: "ASHLEY REBECA MARQUEZ ZOLIZ",
        Edad: 8,
        Estatus_Salario: "-",
      },
      {
        Parentesco: "Children",
        Nombre: "ASHLEY REBECA MARQUEZ ZOLIZ",
        Edad: 7,
        Estatus_Salario: "-",
      },
      {
        Parentesco: "Children",
        Nombre: "ASHLEY REBECA MARQUEZ ZOLIZ",
        Edad: 6,
        Estatus_Salario: "-",
      },
      {
        Parentesco: "Children",
        Nombre: "ASHLEY REBECA MARQUEZ ZOLIZ",
        Edad: 5,
        Estatus_Salario: "-",
      },
      {
        Parentesco: "Children",
        Nombre: "ASHLEY REBECA MARQUEZ ZOLIZ",
        Edad: 4,
        Estatus_Salario: "-",
      },
    ],
    dataType = "family",
    isDownload = true,
    hideButton,
  } = props;
  const { columnHeaders, createRow } = dataTypesConfig[dataType];

  const [showAllRows, setShowAllRows] = useState(isDownload);

  const rows = tableData.map((item) => {
    const config = dataTypesConfig[dataType];
    const createParams = config.createParams.map((param) => item[param]);
    return createRow(...createParams);
  });

  const displayedRows = showAllRows ? rows : rows.slice(0, dataType == "geoloc" ? 3 : 2);

  const handleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };
  const getResidenciaColoniaText = (htmlString, columnName, columnIndex) => {
    if (columnName === "PEP") {
      if (htmlString) {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <Box
                sx={{
                  width: { xs: 10, md: 25 },
                  height: { xs: 10, md: 25 },
                  border: "1px solid #ececed",
                  backgroundColor: htmlString,
                  display: "flex",
                  borderRadius: 50,
                }}
              ></Box>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <Box
                sx={{
                  width: { xs: 10, md: 25 },
                  height: { xs: 10, md: 25 },
                  border: "1px solid #ececed",
                  backgroundColor: "white",
                  display: "flex",
                  borderRadius: 50,
                }}
              ></Box>
            </Box>
          </>
        );
      }
    }

    if (dataType === "geoloc" && columnName === "label") {
      return (
        <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          columnGap: 1,
        }}
      >
        <Box
          sx={{
            width: { xs: 10, md: 25 },
            height: { xs: 10, md: 25 },
            border: "1px solid #ececed",
            backgroundColor: tableData[columnIndex].Color.replace("0x", "#"),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 50,
          }}
        >{htmlString}</Box>
      </Box>
      );
    }

    // Parse the HTML string using DOMParser
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");

    // Get the text content from the parsed HTML
    let textContent = parsedHTML.documentElement.textContent;

    // Replace specific HTML tags with an empty string
    textContent = textContent.replace(
      /<div class="textoParaJustificar">.*?<\/div>/gi,
      ""
    );
    textContent = textContent.replace(
      /<div class="textoAzul">.*?<\/div>/gi,
      ""
    );
    // Replace "ww•" with an empty string
    textContent = textContent.replace(/ww•/gi, "");

    return textContent.trim();
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <TableContainer sx={{ borderRadius: 1 }}>
        <Table
          sx={{
            borderRadius: 2,
            border: "0px solid rgba(167, 169, 169, 0.16)",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {columnHeaders &&
                columnHeaders.map((header, index) => (
                  // two column static width 50%
                  <StyledTableCell
                    sx={{
                      p: { xs: "5px", md: "10px 16px" },
                      fontSize: { xs: "1.75vw", md: "0.875rem" },
                      width: columnHeaders.length === 2 ? "50%" : "inherit",
                    }}
                    align={index === 0 ? "left" : "center"}
                    component="th"
                    key={index}
                  >
                    {t(`${header}`)}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColorColor: "transparent" }}>
            {displayedRows.map((row, index) => (
              <StyledTableRow
                sx={{ backgroundColor: "transparent" }}
                key={index}
              >
                {Object.entries(row).map(([key, value], cellIndex) => (
                  <StyledTableCell
                    sx={{
                      p: { xs: "5px", md: "10px 16px" },
                      fontSize: { xs: "1.75vw", md: "0.875rem" },
                      width:
                        Object.values(row).length === 2 ? "50%" : "inherit",
                    }}
                    align={cellIndex === 0 ? "left" : "center"}
                    key={cellIndex}
                  >
                    {getResidenciaColoniaText(value, key, index)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
        {tableData.length > 2 && (
          <Button
            sx={{
              backgroundColor: "#E5E5E5",
              height: "30px",
              width: "30px",
              borderRadius: 50,
              minWidth: "28px",
              p: 0,
            }}
            onClick={handleShowAllRows}
          >
            {showAllRows ? (
              <KeyboardArrowUpIcon sx={{ color: "#000000" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "#000000" }} />
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
}
