import React, { useRef, useState } from "react";
import PrivacyAndTermsHeader from "../@common/Header/PrivacyAndTermsHeader";
import PropTypes from "prop-types";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PublicPrivacyNotice = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const descriptionElementRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (descriptionElementRef.current) {
      descriptionElementRef.current.scrollTop = 0;
    }
  };

  return (
    <>
      <PrivacyAndTermsHeader />
      <Box
        sx={{
          backgroundColor: "#fff",
          mt: 14,
          mx: { xs: 2, sm: 8 },
          mb: 2,
          p: 1,
          borderRadius: 1,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography
            sx={{
              fontFamily: "Gilroy-SemiBold",
              fontSize: "20px",
              lineHeight: "35px",
              textAlign: "center",
              color: "#1C212D",
              mb: 0.5,
            }}
          >
            {t("Privacy Notice.Privacy")}
          </Typography>
          <Tabs
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "fit-content",
              mx: "auto",
              "& .MuiTabs-scroller": {
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#EFF1F8",
                p: 0.8,
                borderRadius: "4px",
              },
              "& .MuiTab-root.MuiButtonBase-root": {
                minWidth: { xs: 110, md: 145 },
              },
              "& .Mui-selected": {
                backgroundColor: "#4165F6 !important",
                color: "#ffffff !important",
                borderRadius: "4px",
              },
              "& .MuiTab-textColorPrimary": {
                py: 2,
                textTransform: "capitalize",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
            disableRipple={true}
            value={value}
            onChange={handleChange}
          >
            <Tab label={t("Privacy Notice.ConsultedTab")} {...a11yProps(0)} />
            <Tab label={t("Privacy Notice.UsersTab")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box
          sx={{ flex: 1, maxHeight: {
            sm:"50vh",
            md:"60vh"
          } , overflowY: "auto" }}
          
          ref={descriptionElementRef}
        >
          <TabPanel value={value} index={0}>
            <Typography sx={{ color: "#000000", textAlign: "center", mb: 2 }}>
              <b> {t("Privacy Notice.Consulted.title")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>
              <b>{` ${t("Privacy Notice.Consulted.b1")} `}</b>
              {t("Privacy Notice.Consulted.p1")}{" "}
              <b>({`"${t("Privacy Notice.Consulted.Garangou")}"`})</b>,{" "}
              {t("Privacy Notice.Consulted.p2")}{" "}
              <a href="https://searchlook.mx/" target={"_blank"}>
                https://searchlook.mx/
              </a>{" "}
              {t("Privacy Notice.Consulted.p3")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p4")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p5")}{" "}
              <b> {t("Privacy Notice.Consulted.Garangou")} </b>{" "}
              {t("Privacy Notice.Consulted.p6")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.b2")}</b>{" "}
              {t("Privacy Notice.Consulted.p7")}{" "}
              <b>{t("Privacy Notice.Consulted.b3")}</b>{" "}
              {t("Privacy Notice.Consulted.p8")}
            </Typography>
            <Grid container>
              <Grid
                item
                xs={7}
                sx={{
                  borderWidth: "1px 0 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  <b>{t("Privacy Notice.Consulted.b4")}</b>
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ border: "1px solid #000", p: 1 }}>
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  {t("Privacy Notice.Consulted.p9")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={7}
                sx={{
                  borderWidth: "0px 0 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  <b>{t("Privacy Notice.Consulted.b5")}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  borderWidth: "0px 1px 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  {t("Privacy Notice.Consulted.p10")}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ color: "#000000", textAlign: "left", mt: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")} </b>{" "}
              {t("Privacy Notice.Consulted.p11")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p11b")}
            </Typography>
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{
                  borderWidth: "1px 0 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  <b>{t("Privacy Notice.Consulted.b6")}</b>
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ border: "1px solid #000", p: 1 }}>
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  {t("Privacy Notice.Consulted.p12")}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.b7")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b8")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p13")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p14")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p15")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b9")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p16")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p17")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b10")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p18")}{" "}
              <span>
                (
                <a href="https://micuenta.infonavit.org.mx/" target="_blank">
                  Infonavit
                </a>
                )
              </span>
              {t("Privacy Notice.Consulted.p19")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.imss_b10")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.imss_p18")}{" "}
              <span>
                (
                <a href="https://serviciosdigitales.imss.gob.mx/semanascotizadas-web/" target="_blank">
                  IMSS
                </a>
                )
              </span>
              {t("Privacy Notice.Consulted.imss_p19")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b11")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p20")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p21")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p22a")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p22b")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b12")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p23")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p24a")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p24b")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b13")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p25a")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p25b")}{" "}
              {t("Privacy Notice.Consulted.p25c")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p25d")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p25e")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b14")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p26")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b15")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p27")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p28")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b16")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p29")}{" "}
              <a href="mailto:contacto@searchlook.mx">contacto@searchlook.mx</a>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p30")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              {t("Privacy Notice.Consulted.p31a")}{" "}
              <b>{t("Privacy Notice.Consulted.Garangou")}</b>{" "}
              {t("Privacy Notice.Consulted.p31b")}{" "}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p32")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Consulted.b17")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p33")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Consulted.p34")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>
                {t("Privacy Notice.Consulted.b18")}{" "}
                <a
                  href="http://inicio.inai.org.mx/SitePages/ifai.aspx"
                  target="_blank"
                >
                  http://inicio.inai.org.mx/SitePages/ifai.aspx
                </a>
              </b>
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography sx={{ color: "#000000", textAlign: "center", mb: 2 }}>
              <b> {t("Privacy Notice.Users.title")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")}</b>
              <b>{` ${t("Privacy Notice.Users.b1")} `}</b>
              {t("Privacy Notice.Users.p1")}{" "}
              <b>({`"${t("Privacy Notice.Users.Garangou")}"`})</b>,{" "}
              {t("Privacy Notice.Users.p2")}{" "}
              <a href="https://searchlook.mx/" target={"_blank"}>
                https://searchlook.mx/
              </a>{" "}
              {t("Privacy Notice.Users.p3")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p4")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p5")}{" "}
              <b> {t("Privacy Notice.Users.Garangou")} </b>{" "}
              {t("Privacy Notice.Users.p6")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.b2")}</b>{" "}
              {t("Privacy Notice.Users.p7")}{" "}
              <b>{t("Privacy Notice.Users.b3")}</b>{" "}
              {t("Privacy Notice.Users.p8")}
            </Typography>
            <Grid container>
              <Grid
                item
                xs={7}
                sx={{
                  borderWidth: "1px 0 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  <b>{t("Privacy Notice.Users.b4")}</b>
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ border: "1px solid #000", p: 1 }}>
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  {t("Privacy Notice.Users.p9")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={7}
                sx={{
                  borderWidth: "0px 0 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  <b>{t("Privacy Notice.Users.b5")}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  borderWidth: "0px 1px 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  {t("Privacy Notice.Users.p10")}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ color: "#000000", textAlign: "left", mt: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")} </b>{" "}
              {t("Privacy Notice.Users.p11")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p11b")}
            </Typography>
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{
                  borderWidth: "1px 0 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#000",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  <b>{t("Privacy Notice.Users.b6")}</b>
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ border: "1px solid #000", p: 1 }}>
                <Typography sx={{ color: "#000000", textAlign: "left" }}>
                  {t("Privacy Notice.Users.p12a")}{" "}
                  <a href="https://searchlook.mx/" target="_blank">
                    https://searchlook.mx/
                  </a>
                  ; {t("Privacy Notice.Users.p12b")}
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.b7")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b8")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p13")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p14")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p15")}
            </Typography>

            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b11")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p20")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p21")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p22a")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p22b")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b12")}</b>
            </Typography>

            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p24a")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p24b")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b13")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p25a")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p25b")} {t("Privacy Notice.Users.p25c")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p25d")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p25e")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b14")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p26")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b15")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p27")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p28")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b16")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p29")}{" "}
              <a href="mailto:contacto@searchlook.mx">contacto@searchlook.mx</a>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p30")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              {t("Privacy Notice.Users.p31a")}{" "}
              <b>{t("Privacy Notice.Users.Garangou")}</b>{" "}
              {t("Privacy Notice.Users.p31b")}{" "}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p32")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>{t("Privacy Notice.Users.b17")}</b>
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p33")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left", mb: 2 }}>
              {t("Privacy Notice.Users.p34")}
            </Typography>
            <Typography sx={{ color: "#000000", textAlign: "left" }}>
              <b>
                {t("Privacy Notice.Users.b18")}{" "}
                <a
                  href="http://inicio.inai.org.mx/SitePages/ifai.aspx"
                  target="_blank"
                >
                  http://inicio.inai.org.mx/SitePages/ifai.aspx
                </a>
              </b>
            </Typography>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default PublicPrivacyNotice;
