import IconButton from "@mui/material/IconButton";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import faceidGeolocImg from "../../../assets/images/faceid-n-geoloc-blue.svg";
import faceidImg from "../../../assets/images/faceid.svg";
import geolocImg from "../../../assets/images/geoloc.svg";

function FaceIDGeoLocComponent({
    clickLocation,
    handleFaceGeoClosed,
    forwardEmail,
    currentItem,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleclosed = () => {
    setIsModalOpen(false);
  };

  const handleGeoLocation = () => {
    forwardEmail(currentItem._id, 'geoloc');
  }

  const handleFaceID = () => {
    forwardEmail(currentItem._id, 'faceid');
  }

  return (
    <div>
      <Modal
        open={true}
        onClose={handleFaceGeoClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
            <div>
              <div
                style={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "block",
                    backgroundColor: "white",
                    fontSize: 14,
                    fontFamily: "Gilroy-Medium",
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                  }}
                >
                  {" "}
                  <img
                    src={faceidGeolocImg}
                    width={"24px"}
                    alt="icon email send"
                    style={{
                      paddingTop: "10px",
                      marginLeft: "14px",
                      display: "block",
                    }}
                  />
                </Typography>
              </div>

              <div 
                disabled={!(currentItem.geoloc_requested) || currentItem.module_type === "empresa"}
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: !currentItem.geoloc_requested ? '#4165f6' : currentItem.geoloc_verified ? 'green' : 'gold',
                  borderRadius: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: `${clickLocation.y}px`,
                  left: `${clickLocation.x}px`,
                  transform: 'translate(-50%, -50%)',
                  marginTop: '-60px',
                  marginLeft: '-60px',
                  transition: "background-color 0.3s, width 0.3s, height 0.3s",
                
                  '&:hover': {
                    backgroundColor: '#4165f6'
                  }
                }}
              >
                <IconButton
                  disabled={!(currentItem.geoloc_requested) || currentItem.module_type === "empresa"}
                  onClick={() => handleGeoLocation()}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        filter: !(currentItem.geoloc_requested) || currentItem.module_type === "empresa" ? "brightness(0.5)" : "none",
                        width: "24px",
                      }}
                      src={geolocImg}
                      width={"18px"}
                      alt="icon email send"
                    />
                  </Box>
                </IconButton>
              </div>
              <div 
                disabled={!(currentItem.faceid_requested) || currentItem.module_type === "empresa"}
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: !currentItem.faceid_requested ? '#4165f6' : currentItem.faceid_verified ? 'green' : 'gold',
                  borderRadius: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: `${clickLocation.y}px`,
                  left: `${clickLocation.x}px`,
                  transform: 'translate(-50%, -50%)',
                  marginTop: '-60px',
                  marginLeft: '60px',
                  transition: "background-color 0.3s, width 0.3s, height 0.3s",
                
                  '&:hover': {
                    backgroundColor: '#4165f6'
                  }
                }}
                >
                <IconButton
                  disabled={!(currentItem.faceid_requested) || currentItem.module_type === "empresa"}
                  onClick={() => handleFaceID()}
                >
                  <Box
                    sx={{
                      p: "7px",
                      height: "40px",
                      width: "40px",
                      mx: "auto",
                      display: "block",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                    style={{
                      filter: !(currentItem.faceid_requested) || currentItem.module_type === "empresa" ? "brightness(0.5)" : "none",
                      width: "24px",
                    }}
                      src={faceidImg}
                      width={"18px"}
                      alt="icon email send"
                    />
                  </Box>
                </IconButton>
              </div>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-100px",
                  marginLeft: "-60px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.GeoLocation")}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: clickLocation.y,
                  left: clickLocation.x,
                  transform: "translate(-50%, -50%)",
                  marginTop: "-100px",
                  marginLeft: "60px",
                  color: "#ffffff",
                  fontSize: 14,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                {t("authorizationpanel.rightContent.buttons.FaceID")}
              </Typography>
            </div>
          </div>
      </Modal>
    </div>
  );
}
export default FaceIDGeoLocComponent;
